import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import ModuleContext from '../../_contexts/ModuleContext';
import TableOfContentsContext from './TableOfContentsContext';
import { SlideSet, getSlideSets } from '../../../../modules/material/_api/SlideSetApi';
import useThrowAsyncError from '../../../../hooks/useThrowAsyncError';
import IdentityContext from '../../../../contexts/IdentityContext';

type SlideSetsContextValue = {
  slideSets: SlideSet[];
  setSlideSets: React.Dispatch<React.SetStateAction<SlideSet[]>>;
  fetchSlideSets: () => void;
  isLoading: boolean;
};

const SlideSetsContext = createContext<SlideSetsContextValue>({
  slideSets: [],
  setSlideSets: () => undefined,
  fetchSlideSets: () => undefined,
  isLoading: false,
});

export default SlideSetsContext;

export function SlideSetsContextProvider({ children }: PropsWithChildren) {
  const handleError = useThrowAsyncError();

  const { identity } = useContext(IdentityContext);
  const { selectedModule } = useContext(ModuleContext);
  const { selectedNode } = useContext(TableOfContentsContext);

  const [slideSets, setSlideSets] = useState<SlideSet[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchSlideSets = useCallback(async () => {
    if (!selectedModule || !selectedNode) return;
    setIsLoading(true);
    try {
      const data = await getSlideSets(selectedModule.id, selectedNode.id);

      setSlideSets(data);
    } catch (e) {
      handleError(e);
    } finally {
      setIsLoading(false);
    }
  }, [handleError, selectedModule, selectedNode]);

  useEffect(() => {
    if (identity.isTeacherAlike) fetchSlideSets();
  }, [fetchSlideSets, identity.isTeacherAlike]);

  return (
    <SlideSetsContext.Provider value={{ slideSets, setSlideSets, fetchSlideSets, isLoading }}>
      {children}
    </SlideSetsContext.Provider>
  );
}
