import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import orderBy from 'lodash/orderBy';
import IdentityContext from '../../../../contexts/IdentityContext';
import useThrowAsyncError from '../../../../hooks/useThrowAsyncError';
import ModuleContext from '../../_contexts/ModuleContext';
import TableOfContentsContext from './TableOfContentsContext';
import { UserMaterial, getUserMaterialWithScores } from '../../../../modules/material/_api/UserMaterialApi';

type UserMaterialContextValue = {
  userMaterials: UserMaterial[];
  setUserMaterials: React.Dispatch<React.SetStateAction<UserMaterial[]>>;
  fetchUserMaterials: () => Promise<void>;
  isLoading: boolean;
};

const UserMaterialContext = createContext<UserMaterialContextValue>({
  userMaterials: [],
  setUserMaterials: () => undefined,
  fetchUserMaterials: () => Promise.resolve(),
  isLoading: false,
});

export default UserMaterialContext;

export function UserMaterialContextProvider({ children }: PropsWithChildren) {
  const handleError = useThrowAsyncError();

  const { identity } = useContext(IdentityContext);
  const { selectedModule } = useContext(ModuleContext);
  const { selectedNode } = useContext(TableOfContentsContext);

  const [userMaterials, setUserMaterials] = useState<UserMaterial[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchUserMaterials = useCallback(async () => {
    if (!selectedModule || !selectedNode) return;
    setIsLoading(true);

    try {
      const { data } = await getUserMaterialWithScores(selectedModule.id, selectedNode.id);

      setUserMaterials(orderBy(data, ['lastUpdatedAt'], ['desc']));
    } catch (e) {
      handleError(e);
    } finally {
      setIsLoading(false);
    }
  }, [handleError, selectedModule, selectedNode]);

  useEffect(() => {
    if (identity.isTeacherAlike) fetchUserMaterials();
  }, [fetchUserMaterials, identity.isTeacherAlike]);

  return (
    <UserMaterialContext.Provider
      value={{
        userMaterials,
        setUserMaterials,
        fetchUserMaterials,
        isLoading,
      }}
    >
      {children}
    </UserMaterialContext.Provider>
  );
}
