import Page from '@pelckmans/elementis/components/page';
import Tabs from '@pelckmans/elementis/components/tabs';
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '../../../hooks/useDocumentTitle';

const TABS = {
  STUDENT: 'STUDENT',
  EXERCISE: 'EXERCISE',
};

export default function Results() {
  const { t } = useTranslation();

  useDocumentTitle(t('PAGES.RESULTS.DOCUMENT_TITLE'));

  return (
    <Page>
      <Tabs>
        <Page.Header
          headerText={t('PAGES.RESULTS.PAGE_TITLE')}
          tabList={
            <Tabs.TabList aria-label={t('PAGES.RESULTS.TAB_LIST.A11Y_LABEL')}>
              <Tabs.Tab id={TABS.STUDENT}>{t('PAGES.RESULTS.TAB_LIST.TABS.STUDENTS')}</Tabs.Tab>
              <Tabs.Tab id={TABS.EXERCISE}>{t('PAGES.RESULTS.TAB_LIST.TABS.EXERCISE')}</Tabs.Tab>
            </Tabs.TabList>
          }
        />
        <Page.Content>
          <Tabs.TabsContent>
            <Tabs.TabPanel id={TABS.STUDENT}>
              <p>Per leerling</p>
            </Tabs.TabPanel>
            <Tabs.TabPanel id={TABS.EXERCISE}>
              <p>Per oefening</p>
            </Tabs.TabPanel>
          </Tabs.TabsContent>
        </Page.Content>
      </Tabs>
    </Page>
  );
}
