import Alert from '@pelckmans/elementis/components/alert';
import Page from '@pelckmans/elementis/components/page';
import Tabs from '@pelckmans/elementis/components/tabs';
import IdentityContext from '../../contexts/IdentityContext';
import PageLayout from '../../layout/Page.ianua';
import { sortModules } from './utils';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import useThrowAsyncError from '../../hooks/useThrowAsyncError';
import { fetchUserModules, fetchVisitedModules, UserModule } from './api/methodsApi';
import Method from './components/Method';
import Role from '../../core/security/role';
import UserRoleAlert from './components/UserRoleAlert';

function MethodList({
  groupedModules,
  phasedOutModules,
  visitedModules,
}: {
  groupedModules: { years: number[]; modules: UserModule[] }[];
  phasedOutModules: { years: number[]; modules: UserModule[] }[];
  visitedModules: string[];
}) {
  const { t } = useTranslation();
  return (
    <>
      <UserRoleAlert />
      <section className="modules-container" aria-label={t('PAGES.MY_METHODS.REGION.MODULES.A11Y_LABEL')}>
        {groupedModules?.map(({ years, modules }) =>
          modules.map((module, index: number) => (
            <Method key={module.id} module={module} position={index} visitedModules={visitedModules} years={years} />
          )),
        )}
      </section>
      {phasedOutModules.length > 0 && (
        <section aria-labelledby="phased-out-heading">
          <div className="block-header">
            <h2 className="block-header__title" id="phased-out-heading">
              {t('PAGES.MY_METHODS.REGION.PHASED_OUT_MODULES.TITLE')}
            </h2>
            <Alert prominence="warning" message={t('PAGES.MY_METHODS.REGION.PHASED_OUT_MODULES.ALERT.MESSAGE')} />
          </div>
          <div className="modules-container">
            {phasedOutModules?.map(({ years, modules }) =>
              modules.map((module, index) => (
                <Method
                  key={module.id}
                  module={module}
                  position={index}
                  visitedModules={visitedModules}
                  years={years}
                />
              )),
            )}
          </div>
        </section>
      )}
    </>
  );
}

export default function MyMethods() {
  const { REACT_APP_P_STUDIO_GROUP } = process.env;
  const {
    identity: { isTeacherAlike, highestRole },
  } = useContext(IdentityContext);
  const isVoGroup = REACT_APP_P_STUDIO_GROUP === 'VO';

  const [groupedModules, setGroupedModules] = useState<{ years: number[]; modules: UserModule[] }[]>([]);
  const [phasedOutModules, setPhasedOutModules] = useState<{ years: number[]; modules: UserModule[] }[]>([]);
  const [visitedModules, setVisitedModuleIds] = useState<string[]>([]);

  const { t } = useTranslation();
  const handleError = useThrowAsyncError();

  useDocumentTitle(t('PAGES.MY_METHODS.DOCUMENT_TITLE'));

  useEffect(() => {
    async function fetchModules() {
      const fetchedUserModules = await fetchUserModules(REACT_APP_P_STUDIO_GROUP);

      if (highestRole === Role.STUDENT) {
        setGroupedModules(sortModules(fetchedUserModules, false));
        return;
      }

      const { phasedOut, others } = fetchedUserModules.reduce<{
        phasedOut: UserModule[];
        others: UserModule[];
      }>(
        (acc, item) => {
          (item.publishingState === 'phasedOut' ? acc.phasedOut : acc.others).push(item);
          return acc;
        },
        { phasedOut: [], others: [] },
      );

      setGroupedModules(sortModules(others, isTeacherAlike));
      setPhasedOutModules(sortModules(phasedOut, isTeacherAlike));
    }

    fetchModules().catch(handleError);
  }, [REACT_APP_P_STUDIO_GROUP, handleError, isTeacherAlike, highestRole]);

  useEffect(() => {
    async function fetchVisited() {
      const modules = await fetchVisitedModules();

      setVisitedModuleIds(modules);
    }
    fetchVisited().catch(handleError);
  }, [handleError]);

  if (isVoGroup) {
    return (
      <PageLayout>
        <Page>
          <Page.Header headerText={t('PAGES.MY_METHODS.PAGE_TITLE')} />
          <Page.Content>
            {groupedModules && (
              <MethodList
                phasedOutModules={phasedOutModules}
                groupedModules={groupedModules}
                visitedModules={visitedModules}
              />
            )}
          </Page.Content>
        </Page>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <Page>
        <Tabs>
          <Page.Header
            headerText={t('PAGES.MY_METHODS.PAGE_TITLE')}
            tabList={
              <Tabs.TabList aria-label={t('PAGES.MY_METHODS.TAB_LIST.A11Y_LABEL')}>
                <Tabs.Tab id="methods">{t('PAGES.MY_METHODS.TAB_LIST.TABS.MY_METHODS')}</Tabs.Tab>
                <Tabs.Tab id="catalogue">{t('PAGES.MY_METHODS.TAB_LIST.TABS.CATALOGUE')}</Tabs.Tab>
              </Tabs.TabList>
            }
          />
          <Tabs.TabsContent>
            <Tabs.TabPanel id="methods">
              <Page.Content>
                {groupedModules && (
                  <MethodList
                    phasedOutModules={phasedOutModules}
                    groupedModules={groupedModules}
                    visitedModules={visitedModules}
                  />
                )}
              </Page.Content>
            </Tabs.TabPanel>
            <Tabs.TabPanel id="catalogue">
              <Page.Content>
                <div>Catalogus</div>
              </Page.Content>
            </Tabs.TabPanel>
          </Tabs.TabsContent>
        </Tabs>
      </Page>
    </PageLayout>
  );
}
