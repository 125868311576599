import { MaintenancePage } from '@pelckmans/business-components/components/maintenance-page';
import { useEffect, useState } from 'react';
import api from '../../api';
import { useTranslation } from 'react-i18next';

export type MaintenanceMessageType = {
  studioGroup: StudioGroup;
  title: string;
  message: string;
  isActive: boolean;
};

export default function Maintenance() {
  const [maintenanceMessage, setMaintenanceMessage] = useState<MaintenanceMessageType | null>(null);
  const { t } = useTranslation();
  const { REACT_APP_P_STUDIO_GROUP } = process.env;

  useEffect(() => {
    window.loadingScreen?.finish();
  }, []);

  useEffect(() => {
    const getCustomMaintenance = async () => {
      const response = await api.get<MaintenanceMessageType>(
        `/studio-groups/${REACT_APP_P_STUDIO_GROUP}/maintenance-message`,
      );
      const maintenanceMessage = response.data;
      if (!maintenanceMessage?.isActive) return;
      setMaintenanceMessage(maintenanceMessage);
    };
    getCustomMaintenance();
  }, [REACT_APP_P_STUDIO_GROUP]);

  return (
    <MaintenancePage
      studioGroup={REACT_APP_P_STUDIO_GROUP}
      translations={{
        title: maintenanceMessage?.title || t(`MODULES.MAINTENANCE.TITLE`),
        message: maintenanceMessage?.message || t(`MODULES.MAINTENANCE.MESSAGE`),
      }}
    />
  );
}
