import { createContext, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useThrowAsyncError from '../../../hooks/useThrowAsyncError';
import { getModuleBySlug, getSubModulesForModule, Module } from '../../../modules/material/_api/ModuleApi';

type ModuleContextValue = {
  module?: Module;
  subModules: Module[];
  selectedModule?: Module;
  findBySlug: (slug?: string) => Module | undefined;
};

const ModuleContext = createContext<ModuleContextValue>({
  module: undefined,
  subModules: [],
  selectedModule: undefined,
  findBySlug: () => undefined,
});

export default ModuleContext;

export function ModuleContextProvider({ children }: PropsWithChildren) {
  const { REACT_APP_P_STUDIO_GROUP } = process.env;

  const { moduleSlug, selectedModuleSlug } = useParams();

  const handleError = useThrowAsyncError();

  const [module, setModule] = useState<Module>();
  const [subModules, setSubModules] = useState<Module[]>([]);
  const [selectedModule, setSelectedModule] = useState<Module>();

  const findBySlug = useCallback(
    (slug?: string) => (slug ? [module, ...subModules].find(m => m?.slug === slug) : undefined),
    [module, subModules],
  );

  useEffect(() => {
    const fetchModule = async () => {
      if (!moduleSlug) return;

      try {
        const { data: module } = await getModuleBySlug(moduleSlug);

        if (module.subModules.length) {
          const { data: subModules } = await getSubModulesForModule(module.id);

          setSubModules(subModules);
        }

        return setModule(module);
      } catch (e) {
        handleError(e);
      }
    };

    fetchModule();
  }, [handleError, moduleSlug]);

  useEffect(() => {
    if (selectedModuleSlug) {
      setSelectedModule(findBySlug(selectedModuleSlug));
    } else {
      setSelectedModule(undefined);
    }
  }, [findBySlug, selectedModuleSlug]);

  return (
    <ModuleContext.Provider
      value={{
        module,
        subModules,
        findBySlug,
        selectedModule,
      }}
    >
      {module?.styling?.primaryColor && (
        <style>
          {`:root .body--${REACT_APP_P_STUDIO_GROUP.toLowerCase()} { --module-color: ${module.styling.primaryColor}}`}
        </style>
      )}
      {children}
    </ModuleContext.Provider>
  );
}
