import { useTranslation } from 'react-i18next';
import ErrorPage from './ErrorPage';
import '../../scss/modules/_errors.scss';
import '../../scss/base/_typography.scss';
import '../../scss/base/_root.scss';
import '../../scss/base/_normalize.scss';
import '@pelckmans/business-components/css/business-components-modules.css';

const TRANSLATIONS = 'MODULES.UNHANDLED_ERROR';

export default function Error() {
  const { t } = useTranslation();

  return (
    <ErrorPage headerText={t(`${TRANSLATIONS}.TITLE`)} link={{ to: '/', text: t(`${TRANSLATIONS}.ACTIONS.GO_BACK`) }} />
  );
}
