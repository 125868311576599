import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { PropsWithChildren, useContext } from 'react';
import FeatureTogglesContext from './contexts/FeatureTogglesContext';
import nlSO from './locales/nl-so.json';
import nlBAO from './locales/nl-bao.json';
import nlVO from './locales/nl-vo.json';
import frES from './locales/fr-es.json';
import nlSOIanua from './locales/nl-so-ianua.json';
import frESIanua from './locales/fr-es-ianua.json';

i18n.use(initReactI18next).init({
  resources: {
    nl: {
      fallback: nlSO,
      SO: nlSO,
      BAO: nlBAO,
      VO: nlVO,
    },
    fr: {
      fallback: frES,
      ES: frES,
    },
  },
  lng: process.env.REACT_APP_P_STUDIO_LANGUAGE,
  defaultNS: process.env.REACT_APP_P_STUDIO_GROUP,
  fallbackNS: 'fallback',
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: process.env.NODE_ENV !== 'test',
  },
});

export default i18n;

export function I18nResourceProvider({ children }: PropsWithChildren) {
  const { REACT_APP_P_STUDIO_GROUP, REACT_APP_P_STUDIO_LANGUAGE } = process.env;

  const { IANUA } = useContext(FeatureTogglesContext);

  if (IANUA) {
    switch (REACT_APP_P_STUDIO_GROUP) {
      case 'SO':
        i18n.addResourceBundle(REACT_APP_P_STUDIO_LANGUAGE, REACT_APP_P_STUDIO_GROUP, nlSOIanua, true, true);
        break;
      case 'ES':
        i18n.addResourceBundle(REACT_APP_P_STUDIO_LANGUAGE, REACT_APP_P_STUDIO_GROUP, frESIanua, true, true);
        break;
      case 'VO':
        i18n.addResourceBundle(REACT_APP_P_STUDIO_LANGUAGE, REACT_APP_P_STUDIO_GROUP, nlSOIanua, true, true);
        break;
      case 'BAO':
        i18n.addResourceBundle(REACT_APP_P_STUDIO_LANGUAGE, REACT_APP_P_STUDIO_GROUP, nlSOIanua, true, true);
        break;
      default:
        break;
    }
  }

  return <>{children}</>;
}
