import { useContext } from 'react';
import { UserSchoolYearResponse } from '../api/MyGroupsApi';
import { MyGroupsContext } from '../context/MyGroupsContext';

export type SchoolYear = {
  displayName: string;
  id: string;
} & UserSchoolYearResponse;

export default function useGroups() {
  const { schoolYears, selectedSchoolYear, onSchoolYearChange, onGroupsChange, groupsWithSchool } =
    useContext(MyGroupsContext);

  return {
    schoolYears,
    selectedSchoolYear,
    onSchoolYearChange,
    onGroupsChange,
    groupsWithSchool,
  };
}
