import Card from '@pelckmans/elementis/components/card';
import Pill from '@pelckmans/elementis/components/pill';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import IdentityContext from '../../../contexts/IdentityContext';
import { buildSubtitle, getStatus, hasAccess } from '../utils';
import { UserModule } from '../../../modules/dashboard/api/DashboardApi';

function Method({
  module,
  position,
  visitedModules,
  years,
}: {
  module: UserModule;
  position: number;
  visitedModules: string[];
  years: number[];
}) {
  const { REACT_APP_P_API_URL, REACT_APP_P_STUDIO_GROUP } = process.env;
  const { t } = useTranslation();
  const { identity } = useContext(IdentityContext);
  const { catalogName, subjectName, image, id, publishingState } = module;
  const userHasAccess = hasAccess(identity, publishingState, id);
  const status = getStatus(userHasAccess, identity, module, !visitedModules.includes(id), t);

  const translations = {
    year: t('PAGES.MY_METHODS.MODULES.PILLS.YEAR'),
    primary: t('PAGES.MY_METHODS.MODULES.PILLS.SUFFIX.PRIMARY'),
    standard: t('PAGES.MY_METHODS.MODULES.PILLS.SUFFIX.STANDARD'),
  };

  const defaultCard = REACT_APP_P_STUDIO_GROUP === 'SO' || REACT_APP_P_STUDIO_GROUP === 'ES';

  return (
    <div className="module">
      <div className="pill">{position === 0 && <Pill>{buildSubtitle(translations, years)}</Pill>}</div>
      <Card
        disabled={!userHasAccess}
        status={{
          children: status,
          'aria-label': t('PAGES.MY_METHODS.MODULES.CARDS.STATUS_GROUP.A11Y_LABEL'),
        }}
        titleText={defaultCard ? catalogName : subjectName}
        subText={defaultCard ? subjectName : catalogName}
        titleTag="h3"
        image={`${REACT_APP_P_API_URL}${image}`}
        imagePosition="top"
        onClick={() => undefined}
      />
    </div>
  );
}

export default Method;
