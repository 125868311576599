import { useTranslation } from 'react-i18next';
import Section from '@pelckmans/elementis/components/section';
import ButtonDropdown from '@pelckmans/elementis/components/button-dropdown';
import Badge from '@pelckmans/elementis/components/badge';
import List from '@pelckmans/elementis/components/list';
import Pill from '@pelckmans/elementis/components/pill';
import SmartschoolIcon from '@pelckmans/elementis/icons/smartschool';
import PelckmansIcon from '@pelckmans/elementis/icons/pelckmans';
import ListGroup from '@pelckmans/elementis/components/list-group';
import { useContext } from 'react';
import useGroups, { SchoolYear } from '../hooks/useGroups';
import AddPersonalGroupModal from '../modals/add-personal-group/AddPersonalGroupModal';
import ModalContext from '../../../contexts/ModalContext';
import { Group } from '../api/MyGroupsApi';

const renderSchoolNameWithCities = (school: Group['school']) => {
  if (school.cities) {
    return `${school.name}, ${school.cities}`;
  }

  return school.name;
};

type Props = {
  schoolYear: SchoolYear;
};

function OverviewMyGroups({ schoolYear }: Props) {
  const { groupsWithSchool, selectedSchoolYear, onGroupsChange } = useGroups();
  const { t } = useTranslation();
  const { showModal } = useContext(ModalContext);

  const addGroups = !schoolYear.hasPassed;

  return (
    <Section
      title={t('PAGES.MY_GROUPS.SECTION_TITLE', {
        schoolYearStartYear: schoolYear.startYear,
        schoolYearEndYear: schoolYear.endYear,
      })}
      actions={
        addGroups && selectedSchoolYear ? (
          <ButtonDropdown
            buttonProps={{ prominence: 'secondary', size: 'sm' }}
            title={t('PAGES.MY_GROUPS.ADD_GROUP.BUTTON_DROPDOWN.TITLE')}
          >
            <ButtonDropdown.Item
              onClick={() => {
                showModal(
                  <AddPersonalGroupModal
                    id="add-personal-group-modal"
                    schoolYear={selectedSchoolYear}
                    onGroupsChange={onGroupsChange}
                  />,
                );
              }}
              value="personal"
              text={t('PAGES.MY_GROUPS.ADD_GROUP.BUTTON_DROPDOWN.ITEMS.PERSONAL_GROUP')}
            />
          </ButtonDropdown>
        ) : undefined
      }
    >
      <ListGroup>
        {(groupsWithSchool || []).map(({ school, groups }) => {
          return (
            <List key={school.id} title={renderSchoolNameWithCities(school)} prominent titleTag="h3">
              {groups.map(group => (
                <List.Item
                  key={group.id}
                  title={group.name}
                  titleTag="h4"
                  icon={group.smartschoolGroupId ? <SmartschoolIcon /> : <PelckmansIcon />}
                  badgeGroupProps={{
                    role: 'group',
                    'aria-label': t('PAGES.MY_GROUPS.STUDENTS.A11Y_LABEL'),
                    children: (
                      <>
                        {group.totalAccepted > 0 && (
                          <Badge
                            prominence="success"
                            aria-label={t('PAGES.MY_GROUPS.STUDENTS.ACCEPTED.A11Y_LABEL', {
                              amount: group.totalAccepted,
                            })}
                          >
                            {group.totalAccepted}
                          </Badge>
                        )}
                        {group.totalPending > 0 && (
                          <Badge
                            prominence="warning"
                            aria-label={t('PAGES.MY_GROUPS.STUDENTS.PENDING.A11Y_LABEL', {
                              amount: group.totalPending,
                            })}
                          >
                            {group.totalPending}
                          </Badge>
                        )}
                        {group.totalDeclined > 0 && (
                          <Badge
                            prominence="danger"
                            aria-label={t('PAGES.MY_GROUPS.STUDENTS.DECLINED.A11Y_LABEL', {
                              amount: group.totalDeclined,
                            })}
                          >
                            {group.totalDeclined}
                          </Badge>
                        )}
                      </>
                    ),
                  }}
                  pillGroupProps={{
                    role: 'group',
                    'aria-label': t('PAGES.MY_GROUPS.SUBJECTS.A11Y_LABEL'),
                    children: group.subjects.map(s => <Pill key={s}>{s}</Pill>),
                  }}
                />
              ))}
            </List>
          );
        })}
      </ListGroup>
    </Section>
  );
}

export default OverviewMyGroups;
