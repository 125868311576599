import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Routes from '../routes.json';
import { SupportedUserLanguage } from '@pelckmans/business-components/context/LanguageContext';

export const routeFor = (lang: string) => (route: keyof typeof Routes) => Routes[route][lang as SupportedUserLanguage];

export default function useRouting() {
  const {
    i18n: { language },
  } = useTranslation();
  const { REACT_APP_P_STUDIO_GROUP } = process.env;
  const isVo = REACT_APP_P_STUDIO_GROUP === 'VO';

  const r = useCallback(
    (route: keyof typeof Routes, params?: Record<string, string>) => {
      if (isVo && route === 'my-groups') {
        route = 'my-courses';
      }
      let path = routeFor(language)(route);

      if (!params) return path;

      return Object.entries(params).reduce(
        /*
          - replace the placeholders in the route with their actual values
          - replace the question mark in optional placeholders with an empty string
        */
        (p, [placeholder, value]) => p.replace(`:${placeholder}`, value).replace('?', ''),
        path,
      );
    },
    [isVo, language],
  );

  return { r };
}
