import { PropsWithChildren } from 'react';
import { TableOfContentsContextProvider } from './TableOfContentsContext';
import { MedialinksContextProvider } from './MedialinksContext';
import { SlideSetsContextProvider } from './SlideSetsContext';
import { UserMaterialContextProvider } from './UserMaterialContext';

export default function TocDataProvider({ children }: PropsWithChildren) {
  return (
    <TableOfContentsContextProvider>
      <MedialinksContextProvider>
        <UserMaterialContextProvider>
          <SlideSetsContextProvider>{children}</SlideSetsContextProvider>
        </UserMaterialContextProvider>
      </MedialinksContextProvider>
    </TableOfContentsContextProvider>
  );
}
