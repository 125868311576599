import { createContext, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { ModuleContextProvider } from './ModuleContext';
import Page from '../../../layout/Page.ianua';

type MaterialDataContextValue = {
  selectedNodeIds: string[];
  setSelectedNodeIds: React.Dispatch<React.SetStateAction<string[]>>;
};

export const MaterialDataContext = createContext<MaterialDataContextValue>({
  selectedNodeIds: [],
  setSelectedNodeIds: () => {},
});

export default function MaterialDataProvider() {
  const [selectedNodeIds, setSelectedNodeIds] = useState<string[]>([]);

  return (
    <MaterialDataContext.Provider
      value={{
        selectedNodeIds,
        setSelectedNodeIds,
      }}
    >
      <ModuleContextProvider>
        <Page>
          <Outlet />
        </Page>
      </ModuleContextProvider>
    </MaterialDataContext.Provider>
  );
}
