import List from '@pelckmans/elementis/components/list';
import type { Medialink } from '../../../../modules/material/_api/MedialinksApi';
import { MaterialIcon } from './MaterialIcon';
import { UserMaterial } from '../../../../modules/material/_api/UserMaterialApi';
import { SlideSet } from '../../../../modules/material/_api/SlideSetApi';

type Material = Medialink | UserMaterial | SlideSet;
export type MaterialType = 'user-material' | 'medialink' | 'slide-set';

type MaterialListProps = {
  materials: Material[];
  materialType: MaterialType;
  title: string;
  isLoading: boolean;
};

function determineIconByMaterialType(materialType: MaterialType, material: Material) {
  return (
    <MaterialIcon
      materialType={materialType}
      contentType={'contentType' in material ? material.contentType : undefined}
      isUserSlideSet={'userId' in material ? Boolean(material.userId) : undefined}
    />
  );
}

export default function MaterialList({ materials, materialType, title, isLoading }: MaterialListProps) {
  return (
    <List title={title} titleTag="h3" prominent isSkeleton={isLoading}>
      {materials.map(material => {
        const key = materialType === 'slide-set' ? `${material.id}-${(material as SlideSet).versionId}` : material.id;
        return (
          <List.Item
            key={key}
            title={material.name}
            titleTag="h4"
            icon={determineIconByMaterialType(materialType, material)}
          />
        );
      })}
    </List>
  );
}
