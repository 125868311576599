import Page from '@pelckmans/elementis/components/page';
import { useContext } from 'react';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { useTranslation } from 'react-i18next';
import TeacherMaterialSection from './components/teacher/TeacherMaterialSection';
import TocDataProvider from './_contexts/TocDataProvider';
import IdentityContext from '../../../contexts/IdentityContext';
import TableOfContentsContext from './_contexts/TableOfContentsContext';
import ModuleContext from '../_contexts/ModuleContext';

export function Toc() {
  const { t } = useTranslation();

  useDocumentTitle(t('MODULES.MATERIAL.BROWSE.STATE_TITLE'));

  const { module } = useContext(ModuleContext);
  const { selectedNode } = useContext(TableOfContentsContext);
  const { identity } = useContext(IdentityContext);

  if (!module || !selectedNode) return null;

  function renderContent() {
    if (identity.isTeacherAlike) {
      return <TeacherMaterialSection />;
    }

    return null;
  }

  return (
    <Page>
      <Page.Header headerText={selectedNode.title} backgroundImageUrl={module.styling?.visual} />
      <Page.Content>{renderContent()}</Page.Content>
    </Page>
  );
}

export default function WrappedToc() {
  return (
    <TocDataProvider>
      <Toc />
    </TocDataProvider>
  );
}
