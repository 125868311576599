import { SupportedUserLanguage } from '@pelckmans/business-components/context/LanguageContext';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const routes = {
  MY_METHODS: {
    route: {
      nl: 'dashboard',
      fr: 'tableau-de-bord',
    },
  },
  MY_GROUPS: {
    route: {
      nl: 'mijn-klassen',
      fr: 'mes-classes',
    },
  },
  MATERIAL: {
    route: {
      nl: ':subjectSlug/:methodSlug/:moduleSlug',
      fr: ':subjectSlug/:methodSlug/:moduleSlug',
    },
  },
  'MATERIAL.TOC': {
    route: {
      nl: 'materiaal/:selectedModuleSlug?',
      fr: 'materiel/:selectedModuleSlug?',
    },
  },
  'MATERIAL.RESULTS': {
    route: {
      nl: 'resultaten',
      fr: 'resultats',
    },
  },
} as const;

export function useRouting() {
  const {
    i18n: { language },
  } = useTranslation();

  /**
   * @description Get the translated route for a key.
   */
  const getRoute = useCallback(
    (route: keyof typeof routes) => routes[route].route[language as SupportedUserLanguage],
    [language],
  );

  /**
   * @description Build path based on a route key. Used to build a path to navigate to.
   */
  const buildPath = useCallback(
    (route: keyof typeof routes, params: Record<string, string>) => {
      const basePath = getRoute(route);

      return Object.entries(params).reduce<string>((path, [key, value]) => path.replace(`:${key}`, value), basePath);
    },
    [getRoute],
  );

  return { getRoute, buildPath };
}
