import { useTranslation } from 'react-i18next';
import Dialog from '@pelckmans/elementis/components/dialog';
import Button from '@pelckmans/elementis/components/button';
import TextField from '@pelckmans/elementis/components/text-field';
import { Dispatch, FormEventHandler, SetStateAction, useCallback, useContext, useState } from 'react';
import ModalContext from '../../../../contexts/ModalContext';
import { SchoolYear, sortGroups } from '../../context/MyGroupsContext';
import { createGroup, fetchGroups, Group, Subject, UserSchool } from '../../api/MyGroupsApi';
import { useFormHandling } from '@pelckmans/business-components/hooks/useFormHandling';
import useThrowAsyncError from '../../../../hooks/useThrowAsyncError';
import SchoolSearchSelector from './components/SchoolSearchSelector';
import SubjectSearchSelector from './components/SubjectSearchSelector';

type Props = {
  id: string;
  schoolYear: SchoolYear;
  onGroupsChange: Dispatch<SetStateAction<Group[] | undefined>>;
};

type FormValues = {
  groupName: string;
  school: UserSchool | null;
  subjects: Subject[];
};

const FORM_ID = 'add-personal-group-form';

const initialFormValues: FormValues = {
  groupName: '',
  school: null,
  subjects: [],
};

function AddPersonalGroupModal({ id, schoolYear, onGroupsChange }: Props) {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const { closeModal } = useContext(ModalContext);
  const handleError = useThrowAsyncError();

  const validateForm = useCallback(
    (values: FormValues) => {
      const result: Record<string, string> = {};

      const { groupName, school, subjects } = values;

      if (groupName?.length === 0) {
        result.groupName = t('PAGES.MY_GROUPS.ADD_GROUP.MODALS.ADD_PERSONAL_GROUP.BODY.FORM.GROUP.ERROR');
      }

      if (school === null) {
        result.school = t('PAGES.MY_GROUPS.ADD_GROUP.MODALS.ADD_PERSONAL_GROUP.BODY.FORM.SCHOOL.ERROR');
      }

      if (subjects.length === 0) {
        result.subjects = t('PAGES.MY_GROUPS.ADD_GROUP.MODALS.ADD_PERSONAL_GROUP.BODY.FORM.SUBJECT.ERROR');
      }

      return result;
    },
    [t],
  );
  const { formValues, touched, errors, handleBlur, handleChange, isFormValid } = useFormHandling(
    initialFormValues,
    validateForm,
  );

  const handleClose = () => {
    closeModal(id);
  };

  const [submitted, setSubmitted] = useState(false);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault();

    setSubmitted(true);

    if (!isFormValid) return;

    try {
      await createGroup({
        name: formValues.groupName,
        school: {
          id: formValues.school!.id,
          isSmartschool: formValues.school!.isSmartschool,
        },
        subjects: formValues.subjects.map(x => x.id),
        schoolYear: schoolYear,
      });

      const groups = await fetchGroups(schoolYear);

      onGroupsChange(sortGroups(groups, language));

      handleClose();
    } catch (error) {
      handleError(error);
    } finally {
      setSubmitted(false);
    }
  };

  return (
    <Dialog
      id={id}
      modal
      size="md"
      onClose={handleClose}
      aria-label={t('PAGES.MY_GROUPS.ADD_GROUP.MODALS.ADD_PERSONAL_GROUP.A11Y_LABEL')}
    >
      <Dialog.Header
        title={t('PAGES.MY_GROUPS.ADD_GROUP.MODALS.ADD_PERSONAL_GROUP.HEADER.TITLE')}
        onClose={handleClose}
        aria-label={t('PAGES.MY_GROUPS.ADD_GROUP.MODALS.ADD_PERSONAL_GROUP.HEADER.ACTIONS.CLOSE')}
      />
      <Dialog.Body>
        <form noValidate id={FORM_ID} name={FORM_ID} onSubmit={handleSubmit} className="add-group-form">
          <span className="modal-content-title">
            {t('PAGES.MY_GROUPS.ADD_GROUP.MODALS.ADD_PERSONAL_GROUP.BODY.TITLE')}
          </span>
          <div className="form-fields">
            <TextField
              id="groupName"
              name="groupName"
              labelText={t('PAGES.MY_GROUPS.ADD_GROUP.MODALS.ADD_PERSONAL_GROUP.BODY.FORM.GROUP.LABEL')}
              placeholder={t('PAGES.MY_GROUPS.ADD_GROUP.MODALS.ADD_PERSONAL_GROUP.BODY.FORM.GROUP.PLACEHOLDER')}
              value={formValues.groupName}
              required
              onChange={handleChange}
              onBlur={handleBlur}
              errorText={(touched.groupName || submitted) && errors.groupName ? errors.groupName : undefined}
              maxLength={25}
            />
            <SchoolSearchSelector
              value={formValues.school}
              onChange={school => handleChange({ target: { name: 'school', value: school } })}
              errorText={(touched.school || submitted) && errors.school ? errors.school : undefined}
            />
            <SubjectSearchSelector
              value={formValues.subjects}
              onChange={subjects => handleChange({ target: { name: 'subjects', value: subjects } })}
              errorText={(touched.subjects || submitted) && errors.subjects ? errors.subjects : undefined}
            />
          </div>
        </form>
      </Dialog.Body>
      <Dialog.Footer>
        <Button prominence="neutral" onClick={handleClose}>
          {t('PAGES.MY_GROUPS.ADD_GROUP.MODALS.ADD_PERSONAL_GROUP.FOOTER.ACTIONS.CANCEL')}
        </Button>
        <Button prominence="success" type="submit" form={FORM_ID}>
          {t('PAGES.MY_GROUPS.ADD_GROUP.MODALS.ADD_PERSONAL_GROUP.FOOTER.ACTIONS.SUBMIT')}
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
}

export default AddPersonalGroupModal;
