import { Component, PropsWithChildren } from 'react';
import axios from 'axios';
import Error from './Error';
import Maintenance from './Maintenance';
import { RefreshTokenFailedError } from '../../api/BearerToken';
import AutoSignedOff from './AutoSignedOff';
import { I18nextProvider } from 'react-i18next';
import i18n from '../../i18n';

type Props = PropsWithChildren<{}>;

type State = { error: unknown | null };

class ErrorBoundary extends Component<Props, State> {
  state: State = { error: null };

  static getDerivedStateFromError(error: unknown) {
    return { error };
  }

  determineErrorComponent() {
    if (axios.isAxiosError(this.state.error) && this.state.error.response?.status === 503) return <Maintenance />;
    if (this.state.error instanceof RefreshTokenFailedError) return <AutoSignedOff />;
    return <Error />;
  }

  render() {
    if (this.state.error) {
      return <I18nextProvider i18n={i18n}>{this.determineErrorComponent()}</I18nextProvider>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
