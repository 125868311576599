import SelectField from '@pelckmans/elementis/components/select-field';
import { useTranslation } from 'react-i18next';
import { Subject, fetchSubjects } from '../../../api/MyGroupsApi';
import { useEffect, useMemo, useState } from 'react';
import useThrowAsyncError from '../../../../../hooks/useThrowAsyncError';

type Props = {
  onChange: (subjects: Subject[]) => void;
  value: Subject[];
  errorText?: string;
};

function SubjectSearchSelector({ onChange, value, errorText }: Props) {
  const { t } = useTranslation();
  const handleError = useThrowAsyncError();

  const [isLoading, setIsLoading] = useState(true);
  const [subjects, setSubjects] = useState<Subject[]>([]);
  const [searchValue, onSearchValueChange] = useState('');

  useEffect(() => {
    const getSubjects = async () => {
      const fetchedSubjects = await fetchSubjects();
      setSubjects(fetchedSubjects);
    };

    getSubjects()
      .catch(handleError)
      .finally(() => setIsLoading(false));
  }, [handleError]);

  const subjectsJsx = useMemo(() => {
    if (subjects.length === 0) {
      return [];
    }

    return subjects
      .filter(x => x.name.toLowerCase().includes(searchValue.toLowerCase()))
      .map(subject => (
        <SelectField.Item key={subject.id} value={subject} selected={value?.includes(subject)} text={subject.name} />
      ));
  }, [searchValue, subjects, value]);

  return (
    <SelectField
      required
      multiple
      searchable
      value={value}
      onChange={onChange}
      errorText={errorText}
      isLoading={isLoading}
      renderValueContent={option => option.name}
      selectedValueLabel={t('PAGES.MY_GROUPS.ADD_GROUP.MODALS.ADD_PERSONAL_GROUP.BODY.FORM.SUBJECT.SELECTED_LABEL')}
      placeholder={t('PAGES.MY_GROUPS.ADD_GROUP.MODALS.ADD_PERSONAL_GROUP.BODY.FORM.SUBJECT.PLACEHOLDER')}
      labelText={t('PAGES.MY_GROUPS.ADD_GROUP.MODALS.ADD_PERSONAL_GROUP.BODY.FORM.SUBJECT.LABEL')}
      dropdownMenuProps={{
        ariaLabel: t('PAGES.MY_GROUPS.ADD_GROUP.MODALS.ADD_PERSONAL_GROUP.BODY.FORM.SUBJECT.A11Y_LABEL'),
      }}
      searchProps={{
        searchValue,
        onSearchValueChange,
        inputProps: {
          ariaLabel: t('PAGES.MY_GROUPS.ADD_GROUP.MODALS.ADD_PERSONAL_GROUP.BODY.FORM.SUBJECT.SEARCH.A11Y_LABEL'),
        },
      }}
    >
      {subjectsJsx}
    </SelectField>
  );
}

export default SubjectSearchSelector;
