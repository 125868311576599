import Section from '@pelckmans/elementis/components/section';
import ListGroup from '@pelckmans/elementis/components/list-group';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { useTranslation } from 'react-i18next';
import MaterialList from '../MaterialList';
import { useContext } from 'react';
import MedialinksContext from '../../_contexts/MedialinksContext';
import SlideSetsContext from '../../_contexts/SlideSetsContext';
import UserMaterialContext from '../../_contexts/UserMaterialContext';

export default function TeacherMaterialSection() {
  const { t } = useTranslation();
  const { medialinks, isLoading: medialinkIsLoading } = useContext(MedialinksContext);
  const { userMaterials, isLoading: userMaterialIsLoading } = useContext(UserMaterialContext);
  const { slideSets, isLoading: slideSetIsLoading } = useContext(SlideSetsContext);

  const medialinksSortedByContentType = sortBy(medialinks, 'contentType.category', 'contentType.name');

  const medialinksSortedByCategory = groupBy(
    medialinksSortedByContentType,
    ({ contentType }) => contentType.category || contentType.name,
  );

  return (
    <Section title={t(`PAGES.TOC_LEVEL.SECTIONS.TEACHER.MATERIALS.TITLE`)}>
      <ListGroup>
        <MaterialList
          key="user-materials"
          materialType="user-material"
          materials={userMaterials}
          title={t(`PAGES.TOC_LEVEL.SECTIONS.TEACHER.MATERIALS.SUB_TITLE_OWN_MATERIALS`)}
          isLoading={userMaterialIsLoading}
        />
        <MaterialList
          key="slide-sets"
          materialType="slide-set"
          materials={slideSets}
          title={t(`PAGES.TOC_LEVEL.SECTIONS.TEACHER.MATERIALS.SUB_TITLE_SLIDE_SETS`)}
          isLoading={slideSetIsLoading}
        />
        {Object.keys(medialinksSortedByCategory).map(category => (
          <MaterialList
            key={category}
            materialType="medialink"
            materials={medialinksSortedByCategory[category]}
            title={category}
            isLoading={medialinkIsLoading}
          />
        ))}
      </ListGroup>
    </Section>
  );
}
