import { PropsWithChildren } from 'react';

export default function Page({ children }: PropsWithChildren) {
  return (
    <div className="page">
      <aside className="sidebar" />
      <header className="header" />
      <main>{children}</main>
    </div>
  );
}
