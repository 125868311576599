import { Route } from 'react-router-dom';
import RequireAuth from '../components/routing/RequireAuth';
import { lazy, useContext } from 'react';
import IdentityContext from '../contexts/IdentityContext';
import MyGroups from '../pages/my-groups';
import MyMethods from '../pages/my-methods';
import { withSuspense } from '../utils/suspense';
import { useRouting } from '../routes';
import Toc from '../pages/material/toc';
import Results from '../pages/material/results';

const MaterialDataProvider = lazy(() => import('../pages/material/_contexts/MaterialDataProvider'));
const AppLayoutIanua = lazy(() => import('../AppLayout.ianua'));

function useIanuaRouter() {
  const { identity } = useContext(IdentityContext);
  const { isTeacherAlike } = identity;
  const { getRoute } = useRouting();

  return (
    <Route path="/ianua">
      <Route element={withSuspense(AppLayoutIanua)}>
        <Route element={<RequireAuth />}>
          <Route path={getRoute('MY_METHODS')} element={<MyMethods />} />
          {isTeacherAlike && <Route path={getRoute('MY_GROUPS')} element={<MyGroups />} />}
          <Route path={getRoute('MATERIAL')} element={withSuspense(MaterialDataProvider)}>
            {/** submodule selector if applicable or redirect */}
            <Route index element={<h2>Submodule selector</h2>} />
            {/** toc level for selected module (eg: super or sub module) */}
            <Route path={getRoute('MATERIAL.TOC')} element={<Toc />} />
            {/** results view for super module */}
            {isTeacherAlike && <Route path={getRoute('MATERIAL.RESULTS')} element={<Results />} />}
          </Route>
        </Route>
      </Route>
    </Route>
  );
}

export default useIanuaRouter;
