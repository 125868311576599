import { useEffect, useRef } from 'react';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useTranslation } from 'react-i18next';
import ErrorPage from './ErrorPage';

const TRANSLATION_KEY = 'ERRORS.AUTO_SIGNED_OFF.';

const REDIRECT_TIME = 5000;

/**
 *  This page will be rendered by <ErrorBoundary /> when a RefreshTokenFailedError is thrown
 *
 * This can be force by making changes in BearerToken.ts:
 * set TOKEN_GRACE_PERIOD_IN_SECONDS its value to 999999999
 * throw following object in the then() callback of the /oauth/refresh_token: {response: {status: 401}}
 */
export default function AutoSignedOff() {
  const { t } = useTranslation();

  useDocumentTitle(t(`${TRANSLATION_KEY}DOCUMENT_TITLE`));

  const timeoutRef = useRef<number | undefined>(undefined);

  useEffect(() => {
    timeoutRef.current = window.setTimeout(() => window.location.assign('/'), REDIRECT_TIME);

    return () => window.clearTimeout(timeoutRef.current);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ErrorPage
      headerText={t(`${TRANSLATION_KEY}HEADING`)}
      message={t(`${TRANSLATION_KEY}MESSAGE`)}
      link={{ text: t(`${TRANSLATION_KEY}ACTION`), to: '/' }}
    />
  );
}
