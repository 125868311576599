import api, { ResponsePayloadWithData } from '../../../api';

export type NodeWithoutDescription = {
  id: string;
  title: string;
  displayPrefix: boolean;
  image?: string;
  prefix?: string;
  statistics?: {
    exerciseStudentCount: number;
    exerciseTeacherCount: number;
    node: string;
    studentCount: number;
    teacherCount: number;
    userMaterialTeacherCount?: number;
  };
  nodes?: Node[];
};

export type Node = NodeWithoutDescription & { description: string };

export const fetchTocNodes = async (moduleId: string, statistics?: boolean, includeUserMaterial?: boolean) => {
  const { data } = await api.get<ResponsePayloadWithData<NodeWithoutDescription[]>>(
    `studio/modules/${moduleId}/table-of-content/nodes/sidebar`,
    {
      params: {
        ...(statistics ? { statistics } : {}),
        ...(includeUserMaterial ? { includeUserMaterial } : {}),
      },
    },
  );

  return data;
};
