import UserIcon from '@pelckmans/elementis/icons/user';
import MaterialMediaIcon from '@pelckmans/elementis/icons/material-media';
import MaterialAudioIcon from '@pelckmans/elementis/icons/material-audio';
import MaterialImageIcon from '@pelckmans/elementis/icons/material-image';
import MaterialInfoIcon from '@pelckmans/elementis/icons/material-info';
import MaterialExerciseDownloadIcon from '@pelckmans/elementis/icons/material-exercise-download';
import MaterialVideoIcon from '@pelckmans/elementis/icons/material-video';
import MaterialDialogueIcon from '@pelckmans/elementis/icons/material-dialogue';
import MaterialSiteIcon from '@pelckmans/elementis/icons/material-site';
import MaterialExerciseOnlineIcon from '@pelckmans/elementis/icons/material-exercise-online';
import MaterialTargetIcon from '@pelckmans/elementis/icons/material-target';
import MaterialWordlistIcon from '@pelckmans/elementis/icons/material-wordlist';
import MaterialPresentationIcon from '@pelckmans/elementis/icons/material-presentation';
import MaterialPresentationPresentModusIcon from '@pelckmans/elementis/icons/material-presentation-present-modus';
import { cloneElement, ReactElement } from 'react';

type MaterialIconProps = {
  materialType: 'medialink' | 'user-material' | 'slide-set';
  contentType?: ContentType;
  isUserSlideSet?: boolean;
  className?: string;
};

export type ContentType = {
  name: string;
  icon?: string;
  iconClassName?: string;
};

export const EXISTING_CLASS_ICONS_TO_ELEMENTIS_MAPPER: Record<string, ReactElement> = {
  'icon-file-audio': <MaterialAudioIcon />,
  'icon-file-image': <MaterialImageIcon />,
  'icon-file-info': <MaterialInfoIcon />,
  'icon-file-media': <MaterialMediaIcon />,
  'icon-download-exercise': <MaterialExerciseDownloadIcon />,
  'icon-file-video': <MaterialVideoIcon />,
  'icon-mediatype-dialogue': <MaterialDialogueIcon />,
  'icon-site': <MaterialSiteIcon />,
  'icon-online-exercise': <MaterialExerciseOnlineIcon />,
  'icon-target': <MaterialTargetIcon />,
  'icon-wordlist': <MaterialWordlistIcon />,
  'icon-user-material': <UserIcon />,
  'icon-p-slide-set': <MaterialPresentationIcon />,
  'icon-slide-set': <MaterialPresentationPresentModusIcon />,
};

export function MaterialIcon({ materialType, contentType, className, isUserSlideSet }: MaterialIconProps) {
  if (materialType === 'user-material') {
    return <UserIcon size="sm" data-testid="icon-user-material" />;
  }

  if (materialType === 'slide-set') {
    return isUserSlideSet ? (
      <MaterialPresentationPresentModusIcon size="sm" data-testid="icon-slide-set" />
    ) : (
      <MaterialPresentationIcon size="sm" data-testid="icon-p-slide-set" />
    );
  }

  if (contentType?.iconClassName) {
    return cloneElement(EXISTING_CLASS_ICONS_TO_ELEMENTIS_MAPPER[contentType?.iconClassName], {
      size: 'sm',
      'data-testid': contentType?.iconClassName,
    });
  }

  return contentType?.icon ? (
    <img src={contentType.icon} alt={contentType.name} className={className} />
  ) : (
    <MaterialMediaIcon size="sm" data-testid="icon-file-media" />
  );
}
