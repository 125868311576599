import Pill from '@pelckmans/elementis/components/pill';
import Tooltip from '@pelckmans/elementis/components/tooltip';
import Identity from '../../core/security/identity';
import Role from '../../core/security/role';
import { TFunction } from 'i18next';
import { ModulePublishingState } from '../../@types/module-publishing-state';
import { UserModule } from './api/methodsApi';

export const buildSubtitle = (
  yearTranslation: { year: string; primary: string; standard: string },
  years: number[],
) => {
  const { year, primary, standard } = yearTranslation;

  const formattedYears = years.map(x => `${x}<sup>${x === 1 ? primary : standard}</sup>`);

  const caption =
    formattedYears.length > 2
      ? `${formattedYears.slice(0, -1).join(', ')} & ${formattedYears.slice(-1)}`
      : formattedYears.join(' & ');

  return <span dangerouslySetInnerHTML={{ __html: `${caption} ${year}` }} />;
};

export const sortModules = (modules: UserModule[], isTeacherAlike: boolean) => {
  const groups = new Map<string, UserModule[]>();

  // group by years
  modules.forEach(module => {
    const yearKey = module.years
      .map(Number)
      .sort((a, b) => a - b)
      .join(',');
    if (!groups.has(yearKey)) {
      groups.set(yearKey, []);
    }
    groups.get(yearKey)!.push(module);
  });

  // sort by years
  let sortedAndGroupedByYears = Array.from(groups.entries())
    .map(([key, modules]) => ({
      years: key.split(',').map(Number),
      modules,
    }))
    .sort((a, b) => {
      const minYearA = Math.min(...a.years);
      const minYearB = Math.min(...b.years);

      if (minYearA !== minYearB) {
        return minYearA - minYearB;
      }

      return a.years.length - b.years.length;
    });

  if (!isTeacherAlike) {
    sortedAndGroupedByYears.reverse();
  }

  // sort by subjectSlug and slug
  const sortedBySubjectSlugAndSlug = sortedAndGroupedByYears.map(group => {
    const subjectGroups = new Map<string, UserModule[]>();

    group.modules.forEach(module => {
      if (!subjectGroups.has(module.subjectSlug)) {
        subjectGroups.set(module.subjectSlug, []);
      }
      subjectGroups.get(module.subjectSlug)!.push(module);
    });

    const sortedModules = Array.from(subjectGroups.entries())
      .sort(([subjectA], [subjectB]) => subjectA.localeCompare(subjectB))
      .flatMap(([_, modules]) => modules.sort((a, b) => a.slug.localeCompare(b.slug)));

    return {
      years: group.years,
      modules: sortedModules,
    };
  });
  return sortedBySubjectSlugAndSlug;
};

export const hasAccess = (identity: Identity, publishingState: ModulePublishingState, moduleId: string) => {
  if (
    identity.hasAnyRole([Role.SYSADMIN, Role.PRODUCTION, Role.EDITOR, Role.PUBLISHER]) ||
    identity.author_modules.includes(moduleId)
  ) {
    return true;
  }
  return !(publishingState === 'preview' && !identity.roles.includes(Role.SALES));
};

export const getStatus = (
  access: boolean,
  identity: Identity,
  module: UserModule,
  newModule: boolean,
  t: TFunction,
) => {
  const { id, publishingState, assignments, isDemo, incomplete } = module;

  const isAuthor = identity.author_modules.includes(module.id);

  const role = t(`ROLES.${isAuthor ? 'AUTHOR' : identity.highestRole.toUpperCase()}`);

  const BASE = 'PAGES.MY_METHODS.MODULES.CARDS.STATUS';

  const statusList = [];

  if (newModule) {
    statusList.push(
      <Tooltip
        key={`newmodule-${id}`}
        id={`newmodule-${id}`}
        truncation="wrapped"
        anchor={
          <Pill tooltip prominence="success" role="status">
            {t(`${BASE}.NEW.LABEL`)}
          </Pill>
        }
      >
        {t(`${BASE}.NEW.TOOLTIP.VALUE`)}
      </Tooltip>,
    );
  }

  if (publishingState === 'preview') {
    statusList.push(
      <Tooltip
        key={`preview-${id}`}
        id={`preview-${id}`}
        truncation="wrapped"
        anchor={
          <Pill tooltip prominence="info" role="status">
            {t(`${BASE}.PREVIEW.LABEL`)}
          </Pill>
        }
      >
        {t(`${BASE}.PREVIEW.TOOLTIP.${access ? 'ACCESS' : 'NO_ACCESS'}`, { role })}
      </Tooltip>,
    );
  }

  if (publishingState === 'review') {
    statusList.push(
      <Tooltip
        key={`review-${id}`}
        id={`review-${id}`}
        truncation="wrapped"
        anchor={
          <Pill tooltip prominence="info" role="status">
            {t(`${BASE}.REVIEW.LABEL`)}
          </Pill>
        }
      >
        {t(`${BASE}.REVIEW.TOOLTIP.VALUE`, { role })}
      </Tooltip>,
    );
  }

  if (incomplete) {
    statusList.push(
      <Tooltip
        key={`incomplete-${id}`}
        id={`incomplete-${id}`}
        truncation="wrapped"
        anchor={
          <Pill tooltip prominence="warning" role="status">
            {t(`${BASE}.INCOMPLETE.LABEL`)}
          </Pill>
        }
      >
        {t(`${BASE}.INCOMPLETE.TOOLTIP.VALUE`, { role })}
      </Tooltip>,
    );
  }

  if (isDemo) {
    statusList.push(
      <Tooltip
        key={`demo-${id}`}
        id={`demo-${id}`}
        truncation="wrapped"
        anchor={
          <Pill tooltip prominence="danger" role="status">
            {t(`${BASE}.DEMO.LABEL`)}
          </Pill>
        }
      >
        {t(`${BASE}.DEMO.TOOLTIP.VALUE`, { role })}
      </Tooltip>,
    );
  }

  if (assignments >= 1 && role === Role.STUDENT) {
    const ASSIGNMENT_BASE = `${BASE}.ASSIGNMENTS.${assignments === 1 ? 'SINGLE' : 'MULTIPLE'}`;

    statusList.push(
      <Tooltip
        key={`assignments-${id}`}
        id={`assignments-${id}`}
        truncation="wrapped"
        anchor={
          <Pill tooltip role="status">
            {t(`${ASSIGNMENT_BASE}.LABEL`, { amount: assignments })}
          </Pill>
        }
      >
        {t(`${ASSIGNMENT_BASE}.TOOLTIP.VALUE`, { amount: assignments })}
      </Tooltip>,
    );
  }

  return statusList;
};
