import { RequiredFields } from '../../../@types/utils';
import api from '../../../api';
import { Assignment } from '../../../components/my-assignments/api/MyAssignmentsApi';
import { CompletedAssignment, OpenAssignment } from '../assignments/api/AssignmentsStudentApi';
import { NodeHierarchy } from './MaterialApi';

type UserMaterialAssignment = {
  id?: string;
  from: string;
  to: string;
  repeatable: boolean;
  timezone?: string;
  schoolName?: string;
  groupName?: string;
};

type UserMaterialBase = {
  id: string;
  name: string;
  moduleId: string;
  node: string;
  lastUpdatedAt: string;
  hierarchy?: NodeHierarchy[];
  maxScore: number;
  userMaxScore?: number;
  shared?: boolean;
  assignment?: UserMaterialAssignment;
};

export type SharedUserMaterialBase = Omit<UserMaterialBase, 'shared' | 'assignment'> & {
  owner: {
    id?: string;
    firstName: string;
    lastName: string;
  };
  shares: {
    id: string;
    user: {
      id: string;
      firstName: string;
      lastName: string;
    };
    schoolName: string;
    groupName?: string;
    label?: string;
  }[];
  assignment?: RequiredFields<UserMaterialAssignment, 'id'>;
  materialType: 'user-material';
};

type Lti = {
  lti: {
    ltiResource: string;
    platform: 'BookWidgets';
  };
  score?: {
    amountOfItems: number;
    maximum: number;
    achievable: number;
    achieved: number;
  };
  needsGrading?: boolean;
};

export type LtiUserMaterial = UserMaterialBase & Lti;
export type LtiSharedUserMaterial = SharedUserMaterialBase & Lti;

export function isLti(
  userMaterial: UserMaterial | SharedUserMaterial,
): userMaterial is LtiUserMaterial | LtiSharedUserMaterial {
  return 'lti' in userMaterial;
}

type Link = { href: string };

export type LinkUserMaterial = UserMaterialBase & Link;
export type LinkSharedUserMaterial = SharedUserMaterialBase & Link;

export function isLink(userMaterial: UserMaterial | SharedUserMaterial): userMaterial is LinkUserMaterial {
  return 'href' in userMaterial;
}

type File = {
  file: {
    id: string;
    extension: string;
    originalName: string;
    mimeType: string;
  };
};

export type FileUserMaterial = UserMaterialBase & File;
export type FileSharedUserMaterial = SharedUserMaterialBase & File;

export function isFile(
  userMaterial: UserMaterial | SharedUserMaterial,
): userMaterial is FileUserMaterial | FileSharedUserMaterial {
  return 'file' in userMaterial;
}

export function isSharedUserMaterial(
  userMaterial: UserMaterial | SharedUserMaterial,
): userMaterial is SharedUserMaterial {
  return 'owner' in userMaterial;
}

export function isSharedUserMaterialAssignment(
  sharedUserMaterial: UserMaterial | SharedUserMaterial,
): sharedUserMaterial is RequiredFields<SharedUserMaterial, 'assignment'> {
  return isSharedUserMaterial(sharedUserMaterial) && 'assignment' in sharedUserMaterial;
}

export function isScorableUserMaterial(
  userMaterial: UserMaterial | SharedUserMaterial,
): userMaterial is RequiredFields<UserMaterial | SharedUserMaterial, 'userMaxScore'> {
  return 'userMaxScore' in userMaterial;
}

export type UserMaterial = LtiUserMaterial | LinkUserMaterial | FileUserMaterial;
export type SharedUserMaterial = (LtiSharedUserMaterial | LinkSharedUserMaterial | FileSharedUserMaterial) & {
  materialType: 'user-material';
};

type GetUserMaterialQuery = {
  nodeId?: string;
  search?: string;
  includeSubModules?: boolean;
  includeNodeHierarchy?: boolean;
  includeScore?: boolean;
  limit?: number;
  ids?: string;
};

type GetSharedMaterialQuery = {
  nodeId?: string;
  search?: string;
  includeSubModules?: boolean;
  includeNodeHierarchy?: boolean;
  includeScore?: boolean;
  limit?: number;
};

export type UserMaterials = {
  data: UserMaterial[];
  total: number;
};

export const getUserMaterial = async (moduleId: string, query: GetUserMaterialQuery): Promise<UserMaterials> => {
  const { data } = await api.get(`/studio/user/modules/${moduleId}/user-material`, { params: query });

  return {
    // Solves API inconsistency between medialink and userMaterial
    data: data.data.map((userMaterial: any) => ({ ...userMaterial, moduleId: userMaterial.module, module: undefined })),
    total: data.total,
  };
};

export const getUserMaterialWithScores = async (moduleId: string, nodeId: string) => {
  return getUserMaterial(moduleId, { nodeId, includeScore: true });
};

export const getUserMaterialById = async (
  moduleId: string,
  userMaterialId: string,
  query?: GetUserMaterialQuery,
): Promise<UserMaterial> => {
  const { data } = await getUserMaterial(moduleId, { ...query, limit: 1, ids: userMaterialId });

  return data[0];
};

export const getSharedUserMaterial = async (moduleId: string, query: GetSharedMaterialQuery) => {
  const { data } = await api.get<{
    data: (SharedUserMaterial & { module: string })[];
    total: number;
  }>(`/studio/user/modules/${moduleId}/user-material/shared`, { params: query });

  return {
    ...data,
    data: data.data.map(({ module, ...userMaterial }) => ({
      ...userMaterial,
      moduleId: module,
      module: undefined,
    })),
  };
};

export const getSharedUserMaterialWithScores = async (moduleId: string, nodeId: string) => {
  return getSharedUserMaterial(moduleId, { nodeId, includeScore: true });
};

export const deleteUserMaterial = async (moduleId: string, nodeId: string, materialId: string) => {
  await api.delete(`studio/user/modules/${moduleId}/table-of-content/${nodeId}/user-material/${materialId}`);
};

export const updateUserMaterial = async (
  moduleId: string,
  nodeId: string,
  materialId: string,
  updatedValues: Partial<Omit<UserMaterial, 'id'>>,
) => {
  // PATCH API does not return the full UserMaterial
  const { data } = await api.patch<Omit<UserMaterial, 'hierarchy' | 'shared' | 'assignment'>>(
    `studio/user/modules/${moduleId}/table-of-content/${nodeId}/user-material/${materialId}`,
    updatedValues,
  );

  return data;
};

export const getSignedUrl = async (moduleId: string, materialId: string) => {
  const { data } = await api.get<{
    url: string;
    previewUrl?: string;
    downloadUrl: string;
  }>(`studio/user/modules/${moduleId}/user-material/${materialId}/signed-url`);

  return data;
};

type AssignmentUserMaterialBase = {
  id: string;
  name: string;
  maxScore: number;
  module: string;
  node: string;
  lastUpdatedAt: string;
  userMaxScore?: number;
  hierarchy: NodeHierarchy[];
};

export type AssignmentUserMaterial =
  | (AssignmentUserMaterialBase & Pick<Lti, 'lti'>)
  | (AssignmentUserMaterialBase & Pick<Link, 'href'>)
  | (AssignmentUserMaterialBase & Pick<File, 'file'>);

export async function getMaterialByAssignment(
  assignment: CompletedAssignment | OpenAssignment | Assignment,
): Promise<DistributiveOmit<SharedUserMaterial & { hierarchy: NodeHierarchy[] }, 'materialType'>> {
  const { data } = await api.get<AssignmentUserMaterial>(
    `/studio/user/modules/${assignment.moduleId}/assignments/${assignment.id}/user-material`,
  );
  const owner = 'sharer' in assignment ? assignment.sharer : assignment.owner;

  return {
    ...data,
    moduleId: data.module,
    owner,
    shares: [],
    assignment: {
      ...assignment,
      repeatable: 'allowRepeat' in assignment ? assignment.allowRepeat : false,
    },
  };
}

export async function copyMaterial(moduleId: string, nodeId: string, materialIdsToCopy: string[]) {
  await api.post('/studio/user/user-material/copy', {
    materialIds: materialIdsToCopy,
    moduleId,
    nodeId,
  });
}
