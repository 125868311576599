import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import ModuleContext from '../../../modules/material/_contexts/ModuleContext';
import { useTranslation } from 'react-i18next';
import useRouting from '../../../hooks/useRouting';
import IdentityContext from '../../../contexts/IdentityContext';
import Role from '../../../core/security/role';
import MyAssignmentsContext from '../../../contexts/MyAssignmentsContext';
import { MaterialDataContext } from '../../../modules/material/_contexts/MaterialDataProvider';
import { buildMaterialViewModeClickEvent } from '../../../utils/gaEventBuilder';
import GoogleAnalyticsContext from '../../../contexts/GoogleAnalyticsContext';
import { ViewMode } from '../../../modules/material/module-dashboard/components/ViewModeSelector';

export default function MaterialSidebarNavigation() {
  const { t } = useTranslation();
  const { r } = useRouting();

  const { module, selectedModule } = useContext(ModuleContext);
  const { selectedNodeIds } = useContext(MaterialDataContext);
  const { identity } = useContext(IdentityContext);
  const { assignments } = useContext(MyAssignmentsContext);
  const ga = useContext(GoogleAnalyticsContext);

  if (!module) return null;

  const selectedNodeId = selectedNodeIds[selectedNodeIds.length - 1];

  const routeParams =
    module.subModules.length > 0 || selectedModule?.slug
      ? { selectedModuleSlug: selectedModule?.slug || '' }
      : { selectedModuleSlug: module.slug };

  const { REACT_APP_P_STUDIO_GROUP } = process.env;

  const assignmentsForModule = assignments.filter(a => a.moduleId === module.id);

  const withNodeIdQuery = (path: string) => {
    if (selectedNodeId) {
      const searchParams = new URLSearchParams({ nodeId: selectedNodeId });

      return `${path}?${searchParams}`;
    }

    return path;
  };

  const logClickEvent = (viewMode: ViewMode) => {
    if (!ga) return;

    ga.capture(buildMaterialViewModeClickEvent(viewMode, selectedModule?.name || module.name, 'navClickViaTab'));
  };

  return (
    <nav className="material-sidebar-navigation">
      <ul className="material-sidebar-navigation-list">
        <li>
          <NavLink
            to={withNodeIdQuery(`../${r('material.browse', routeParams)}`)}
            className="navigation-link"
            onClick={() => logClickEvent('browse')}
          >
            <svg role="img" width={24} height={24}>
              <use xlinkHref="#icon-file-media" />
            </svg>
            <span className="caption-2">{t('MODULES.MATERIAL.SIDEBAR_MODE_SELECTOR.ACTIONS.BROWSE')}</span>
          </NavLink>
        </li>

        {(selectedModule || module).hasExerciseEntrance && (
          <li>
            <NavLink
              to={withNodeIdQuery(`../${r('material.exercise', routeParams)}`)}
              className="navigation-link"
              onClick={() => logClickEvent('exercise')}
            >
              <svg role="img" width={24} height={24}>
                <use xlinkHref="#icon-exercise-1" />
              </svg>
              <span className="caption-2">{t('MODULES.MATERIAL.SIDEBAR_MODE_SELECTOR.ACTIONS.EXERCISE')}</span>
            </NavLink>
          </li>
        )}

        {['SO', 'ES', 'VO'].includes(REACT_APP_P_STUDIO_GROUP) &&
          identity.isAuthenticated &&
          identity.hasOnlyRole(Role.STUDENT) && (
            <li>
              <NavLink
                to={`../${r('material.assignments')}`}
                className="navigation-link"
                onClick={() => logClickEvent('assignments')}
              >
                <svg role="img" width={24} height={24}>
                  <use xlinkHref="#icon-assignment" />
                </svg>
                <span className="caption-2">{t('MODULES.MATERIAL.SIDEBAR_MODE_SELECTOR.ACTIONS.ASSIGNMENTS')}</span>
                {assignmentsForModule.length > 0 && (
                  <span className="pill pill--assignments" role="status">
                    {assignmentsForModule.length}
                  </span>
                )}
              </NavLink>
            </li>
          )}

        {['SO', 'ES', 'VO'].includes(REACT_APP_P_STUDIO_GROUP) && identity.isAuthenticated && identity.isTeacherAlike && (
          <>
            <li>
              <NavLink
                to={`../${r('material.assignments')}`}
                className="navigation-link"
                onClick={() => logClickEvent('assignments')}
              >
                <svg role="img" width={24} height={24}>
                  <use xlinkHref="#icon-assignment" />
                </svg>
                <span className="caption-2">
                  {t('MODULES.MATERIAL.SIDEBAR_MODE_SELECTOR.ACTIONS.ASSIGNMENTS_TEACHER')}
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`../${r('material.results')}`}
                className="navigation-link"
                onClick={() => logClickEvent('results')}
              >
                <svg role="img" width={24} height={24}>
                  <use xlinkHref="#icon-results" />
                </svg>
                <span className="caption-2">{t('MODULES.MATERIAL.SIDEBAR_MODE_SELECTOR.ACTIONS.RESULTS')}</span>
              </NavLink>
            </li>
          </>
        )}

        <li>
          <NavLink
            to={`../${r('material.search')}`}
            className="navigation-link"
            onClick={() => logClickEvent('search')}
          >
            <svg role="img" width={24} height={24}>
              <use xlinkHref="#icon-search" />
            </svg>
            <span className="caption-2">{t('MODULES.MATERIAL.SIDEBAR_MODE_SELECTOR.ACTIONS.SEARCH')}</span>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}
