import api from '../../../api';
import { NodeHierarchy } from './MaterialApi';

export type SlideSet = {
  id: number;
  versionId: number;
  name: string;
  moduleId: string;
  nodeId: string;
  state: 'DRAFT' | 'REVIEW' | 'ARCHIVED' | 'PUBLISHED';
  userId?: number;
  shared: boolean;
  hierarchy?: NodeHierarchy[];
};

type GetSlideSetQuery = {
  search?: string;
  includeSubModules?: boolean;
  includeNodeHierarchy?: boolean;
  limit?: number;
};

export const getSlideSets = async (moduleId: string, nodeId: string) => {
  const { data } = await api.get<SlideSet[]>(
    `/studio/user/modules/${moduleId}/table-of-content/${nodeId}/slide-sets?includeDescendants=true`,
  );

  return data;
};

export const deleteUserSlideSet = async (slideSet: SlideSet) => {
  return api.delete(
    `/studio/user/modules/${slideSet.moduleId}/table-of-content/${slideSet.nodeId}/slide-set/${slideSet.id}`,
  );
};

export type SharedSlideSet = {
  id: number;
  name: string;
  materialType: 'slide-set';
  moduleId: string;
  nodeId: string;
  lastUpdatedAt: string;
  versionId: number;
  state: SlideSet['state'];
  owner?: {
    id: string;
    firstName: string;
    lastName: string;
  };
  shares: {
    id: string;
    schoolName: string;
    groupName?: string;
    label?: string;
    user: {
      id: string;
      firstName: string;
      lastName: string;
    };
  }[];
};

export type ListSharedSlideSetsQuery = {
  includeSubModules?: boolean;
  includeNodeHierarchy?: boolean;
  nodeId?: string;
  search?: string;
  limit?: number;
};

export const getSharedSlideSets = async (moduleId: string, query?: ListSharedSlideSetsQuery) => {
  const { data } = await api.get<{
    data: SharedSlideSet[];
    total: number;
  }>(`/studio/user/modules/${moduleId}/slide-sets/shared`, { params: query });

  return { data: data.data.map(ss => ({ ...ss, shared: true, userId: ss.owner?.id })), total: data.total };
};

export const getSlideSetsBySearch = async (moduleId: string, query: GetSlideSetQuery) => {
  const { data } = await api.get<{
    data: SlideSet[];
    total: number;
  }>(`/studio/user/modules/${moduleId}/slide-sets`, { params: query });

  return data;
};
