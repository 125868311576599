import { PropsWithChildren } from 'react';
import { SchoolYearProvider } from '@pelckmans/business-components/context/SchoolYearContext';
import { FeatureTogglesContextProvider } from './contexts/FeatureTogglesContext';
import { IdentityContextProvider } from './contexts/IdentityContext';
import { GoogleAnalyticsContextProvider } from './contexts/GoogleAnalyticsContext';
import { InvitationContextProvider } from './contexts/InvitationContext';
import { AccountContextProvider } from './contexts/AccountContext';
import { LicenseTransferContextProvider } from './contexts/LicenseTransferContext';
import { UserImagesContextProvider } from './contexts/UserImagesContext';
import { MyAssignmentsContextProvider } from './contexts/MyAssignmentsContext';
import { I18nResourceProvider } from './i18n';

export default function AppDataProvider({ children }: PropsWithChildren) {
  const { REACT_APP_P_STUDIO_GROUP } = process.env;

  return (
    <FeatureTogglesContextProvider studioGroup={REACT_APP_P_STUDIO_GROUP}>
      <I18nResourceProvider>
        <GoogleAnalyticsContextProvider>
          <IdentityContextProvider>
            <AccountContextProvider>
              <InvitationContextProvider>
                <LicenseTransferContextProvider>
                  <UserImagesContextProvider>
                    <MyAssignmentsContextProvider>
                      <SchoolYearProvider studioGroup={REACT_APP_P_STUDIO_GROUP}>{children}</SchoolYearProvider>
                    </MyAssignmentsContextProvider>
                  </UserImagesContextProvider>
                </LicenseTransferContextProvider>
              </InvitationContextProvider>
            </AccountContextProvider>
          </IdentityContextProvider>
        </GoogleAnalyticsContextProvider>
      </I18nResourceProvider>
    </FeatureTogglesContextProvider>
  );
}
