import { AxiosResponse } from 'axios';
import sanitize from 'sanitize-html';
import api from '../../../api';
import { ModulePublishingState } from '../../../@types/module-publishing-state';

export type UserModule = {
  assignments: number;
  externalContentUrl?: string;
  hostedExternally: boolean;
  id: string;
  isDemo: boolean;
  incomplete: boolean;
  image: string;
  niceName: string;
  publishingState: ModulePublishingState;
  releaseDate?: string;
  slug: string;
  subjectName: string;
  years: string[];
  subModules: string[];
  subjectSlug: string;
  methodSlug: string;
  catalogName: string;
};

export async function fetchUserModules(studioGroup: StudioGroup): Promise<UserModule[]> {
  const {
    data: { data },
  } = await api.get<AxiosResponse<UserModule[]>>('/studio/user/modules', {
    params: {
      group: studioGroup,
    },
  });

  data.forEach(module => {
    module.catalogName = sanitize(module.catalogName);
  });

  return data;
}

export async function fetchVisitedModules(): Promise<string[]> {
  const {
    data: { data },
  } = await api.get<AxiosResponse<string[]>>('/studio/user/modules/visited');

  return data;
}

export async function markModuleAsVisited(moduleId: string): Promise<void> {
  await api.put(`/studio/user/modules/${moduleId}/visited`);
}
