import { PropsWithChildren, useContext, useEffect, useState, createContext } from 'react';
import ModuleContext from '../../_contexts/ModuleContext';
import TableOfContentsContext from './TableOfContentsContext';
import { getMedialinksForNode, Medialink } from '../../../../modules/material/_api/MedialinksApi';
import useThrowAsyncError from '../../../../hooks/useThrowAsyncError';

type MedialinksContextValue = {
  medialinks: Medialink[];
  setMedialinks: React.Dispatch<React.SetStateAction<Medialink[]>>;
  isLoading: boolean;
};

const MedialinksContext = createContext<MedialinksContextValue>({
  medialinks: [],
  setMedialinks: () => undefined,
  isLoading: false,
});

export default MedialinksContext;

export function MedialinksContextProvider({
  children,
  onlyExercises = false,
}: PropsWithChildren<{ onlyExercises?: boolean }>) {
  const handleError = useThrowAsyncError();

  const { selectedModule } = useContext(ModuleContext);
  const { selectedNode } = useContext(TableOfContentsContext);

  const [medialinks, setMedialinks] = useState<Medialink[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetchMedialinks() {
      if (!selectedModule || !selectedNode) return;
      setIsLoading(true);
      try {
        const { data } = await getMedialinksForNode(selectedModule.id, selectedNode.id, false, onlyExercises);

        setMedialinks(data);
      } catch (e) {
        handleError(e);
      } finally {
        setIsLoading(false);
      }
    }

    fetchMedialinks();
  }, [handleError, onlyExercises, selectedModule, selectedNode]);

  return (
    <MedialinksContext.Provider value={{ medialinks, setMedialinks, isLoading }}>{children}</MedialinksContext.Provider>
  );
}
