import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MaterialDataContext } from '../../_contexts/MaterialDataProvider';
import ModuleContext from '../../_contexts/ModuleContext';
import useThrowAsyncError from '../../../../hooks/useThrowAsyncError';
import { fetchTocNodes, Node } from '../../../../modules/material/_api/TableOfContentsApi';
import { getNodeDescription } from '../../../../utils/getNodeDescription';

export type TableOfContentsContextValue = {
  nodes: Node[];
  selectedNode: Node | undefined;
};

const TableOfContentsContext = createContext<TableOfContentsContextValue>({
  nodes: [],
  selectedNode: undefined,
});

export default TableOfContentsContext;

export function buildNodeParentsById(toc: Node[]) {
  return toc.reduce((parentsMap, node) => {
    const getParents = (n: Node, parents: string[]): void => {
      if (!parentsMap[n.id]) {
        parentsMap[n.id] = parents;
      }

      parents = [...parents, n.id];

      if (n.nodes) {
        n.nodes.forEach(childNode => getParents(childNode, parents));
      }
    };

    getParents(node, []);

    return parentsMap;
  }, {} as Record<string, string[]>);
}

function getNodeById(nodes: Node[], nodeId: string): Node | undefined {
  for (const node of nodes) {
    if (node.id === nodeId) {
      return node;
    }

    if (node.nodes && node.nodes.length > 0) {
      const nestedNode = getNodeById(node.nodes, nodeId);

      if (nestedNode) {
        return nestedNode;
      }
    }
  }

  return undefined;
}

export function TableOfContentsContextProvider({ children }: PropsWithChildren) {
  const { t } = useTranslation();
  const handleError = useThrowAsyncError();
  const { selectedModuleSlug } = useParams();
  const [searchParams] = useSearchParams();

  const { findBySlug } = useContext(ModuleContext);
  const { selectedNodeIds, setSelectedNodeIds } = useContext(MaterialDataContext);

  const [nodes, setNodes] = useState<Node[]>([]);

  const module = useMemo(() => findBySlug(selectedModuleSlug), [findBySlug, selectedModuleSlug]);

  const nodeParentsById = useMemo(() => buildNodeParentsById(nodes), [nodes]);

  const selectedNodeId = selectedNodeIds[selectedNodeIds.length - 1];

  const selectedNode = useMemo(() => {
    const node = getNodeById(nodes, selectedNodeId);

    if (node) return { ...node, description: getNodeDescription(node, t) };
  }, [nodes, selectedNodeId, t]);

  useEffect(() => {
    if (Object.keys(nodeParentsById).length === 0) return;
    if (!searchParams.get('nodeId')) return setSelectedNodeIds([]);

    setSelectedNodeIds([
      ...nodeParentsById[searchParams.get('nodeId') as string],
      searchParams.get('nodeId') as string,
    ]);
  }, [nodeParentsById, searchParams, setSelectedNodeIds]);

  const getNodes = useCallback(async () => {
    try {
      if (!module) return;

      const { data: tocNodes } = await fetchTocNodes(module.id);
      setNodes(tocNodes.map(node => ({ ...node, description: getNodeDescription(node, t) })));
    } catch (e) {
      handleError(e);
    }
  }, [module, handleError, t]);

  useEffect(() => {
    getNodes();
  }, [module, handleError, t, getNodes]);

  return (
    <TableOfContentsContext.Provider
      value={{
        nodes,
        selectedNode,
      }}
    >
      {children}
    </TableOfContentsContext.Provider>
  );
}
