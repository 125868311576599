import { useContext } from 'react';
import IdentityContext from '../../../contexts/IdentityContext';
import Role from '../../../core/security/role';
import Alert from '@pelckmans/elementis/components/alert';
import { useTranslation } from 'react-i18next';

const BASE = 'PAGES.MY_METHODS.ALERTS.USER_ROLES';

function UserRoleAlert() {
  const {
    identity: { highestRole, isAuthor },
  } = useContext(IdentityContext);
  const { t } = useTranslation();

  if ((highestRole === Role.TEACHER || highestRole === Role.STUDENT) && !isAuthor) return null;

  const getMessage = () => {
    if (isAuthor) return t(`${BASE}.AUTHOR`);

    if (highestRole === Role.EXTERNAL) return t(`${BASE}.EXTERNAL`);

    return t(`${BASE}.OTHER`, { role: t(`ROLES.${highestRole.toUpperCase()}`) });
  };

  return <Alert message={getMessage()} />;
}

export default UserRoleAlert;
