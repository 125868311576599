import api, { ResponsePayloadWithData } from '../../../api';
import { NodeHierarchy } from './MaterialApi';
import { Node } from './TableOfContentsApi';
import { RequiredFields } from '../../../@types/utils';
import PdfjsLinkTargets from '../../../constants/PdfjsLinkTargets';
import { FaqTutorialItem } from '../../helpdesk/types';
import WeblinkTargets from '../../../constants/WeblinkTargets';

type User = {
  id: string;
  firstName: string;
  lastName: string;
};

export type ContentType = {
  id: string;
  name: string;
  category: string;
  icon?: string;
  iconClassName?: string;
  printable: boolean;
  isExercise: boolean;
  imagePreview: boolean;
  faqTutorial?: string;
};

export enum MedialinkReviewState {
  NOT_IN_REVIEW = 0,
  IN_REVIEW = 1,
  ACCEPTED = 2,
  DECLINED = 3,
}

export type WeblinkTargetModal = {
  target: typeof WeblinkTargets.MODAL;
  width?: number;
  height?: number;
  reload?: boolean;
};

export type WeblinkTarget =
  | WeblinkTargetModal
  | { target: typeof WeblinkTargets.NEW_WINDOW; href: string }
  | { target: typeof WeblinkTargets.SAME_WINDOW };

type MedialinkWordlist = { id: string };

type MedialinkFile = {
  s3file: {
    id: string;
    originalName: string;
    size: number;
    uploadDate: string;
    mimeType: string;
    preview?: string;
  };
  linkedMedia?: string;
  description?: string;
  forceDownload: boolean;
  pdfLinkTarget?: keyof typeof PdfjsLinkTargets;
};

type MedialinkMiniSite = WeblinkTarget;

type MedialinkMiniDialog = { id: string };

type MedialinkEduhint = {
  id: string;
  showScore: boolean;
};

type AssessmentQExercise = {
  showScore: boolean;
};

type MedialinkEdumatic = {
  id: string;
  showScore: boolean;
};

type MedialinkExternalMedia =
  | MedialinkExternalMediaWeblink
  | MedialinkExternalMediaGeoGebra
  | MedialinkExternalMediaH5P
  | MedialinkExternalMediaYoutube;

type MedialinkExternalMediaWeblink = {
  type: 'weblink';
  href: string;
} & WeblinkTarget;

type MedialinkExternalMediaGeoGebra = {
  type: 'geogebra';
  embedId: string;
  width: number;
  height: number;
  geogebra: {
    resetIcon: boolean;
    labelDragging: boolean;
    toolbarHelp: boolean;
    toolbar: boolean;
    stylebar: boolean;
    menuBar: boolean;
    shiftDrag: boolean;
    inputbar: boolean;
    rightClick: boolean;
    language: 0 | 1;
  };
};

type MedialinkExternalMediaH5P = {
  type: 'h5p';
  embedId: string;
  width?: number;
  height?: number;
};

type MedialinkExternalMediaYoutube = {
  type: 'youtube';
  embedId: string;
  start?: number;
  end?: number;
};

type TeacherAssignment = {
  id?: string;
  from: string;
  to: string;
  repeatable: boolean;
};

type StudentAssignmentBase = {
  from: string;
  to: string;
  repeatable: boolean;
  current: boolean;
  user: User;
};

type PlannedStudentAssignment = StudentAssignmentBase & { id: undefined };
type OnGoingStudentAssignment = StudentAssignmentBase & { id: string };
type StudentAssignment = PlannedStudentAssignment | OnGoingStudentAssignment;
type Assignment = TeacherAssignment | StudentAssignment;

export type Medialink = {
  id: string;
  kind:
    | 'exercise-edumatic'
    | 'exercise-assessmentq'
    | 'exercise-eduhint'
    | 'word-list'
    | 'file'
    | 'mini-site'
    | 'mini-dialog'
    | 'external-media';
  moduleId: string;
  name: string;
  group: string;
  reviewState: MedialinkReviewState;
  contentType: ContentType;
  exerciseCategories?: {
    id: string;
    name: string;
  }[];
  hierarchy: Node[];
  amountOfItems?: number;
  score?: {
    amountOfItems: number;
    maximum: number;
    achievable: number;
    achieved: number;
  };
  updatedAt: string;
  shares: {
    id: string;
    user?: User;
  }[];
  assignments: Assignment[];
  wordList?: MedialinkWordlist;
  file?: MedialinkFile;
  externalMedia?: MedialinkExternalMedia;
  edumaticExercise?: MedialinkEdumatic;
  eduhintExercise?: MedialinkEduhint;
  assessmentQExercise?: AssessmentQExercise;
  miniDialog?: MedialinkMiniDialog;
  miniSite?: MedialinkMiniSite;
  jwplayer?: { id: string; ready: boolean };
};

export type WordlistMedialink = RequiredFields<
  Omit<
    Medialink,
    'file' | 'externalMedia' | 'edumaticExercise' | 'eduhintExercise' | 'miniDialog' | 'miniSite' | 'kind'
  >,
  'wordList'
> & { kind: 'word-list' };

export const isWordlist = (medialink: Medialink): medialink is WordlistMedialink => {
  return medialink.kind === 'word-list';
};

export type FileMedialink = RequiredFields<
  Omit<
    Medialink,
    'wordList' | 'externalMedia' | 'edumaticExercise' | 'eduhintExercise' | 'miniDialog' | 'miniSite' | 'kind'
  >,
  'file'
> & { kind: 'file' };

export function isFile(medialink: Medialink): medialink is FileMedialink {
  return 'file' in medialink;
}

export type MiniSiteMedialink = RequiredFields<
  Omit<
    Medialink,
    'wordList' | 'file' | 'externalMedia' | 'edumaticExercise' | 'eduhintExercise' | 'miniDialog' | 'kind'
  >,
  'miniSite'
> & { kind: 'mini-site'; href?: string };

export const isMiniSite = (medialink: Medialink): medialink is MiniSiteMedialink => {
  return medialink.kind === 'mini-site';
};

export type MiniDialogMedialink = RequiredFields<
  Omit<Medialink, 'wordList' | 'file' | 'externalMedia' | 'edumaticExercise' | 'eduhintExercise' | 'miniSite' | 'kind'>,
  'miniDialog'
> & { kind: 'mini-dialog' };

export const isMiniDialog = (medialink: Medialink): medialink is MiniDialogMedialink => {
  return medialink.kind === 'mini-dialog';
};

export type EduhintMedialink = RequiredFields<
  Omit<Medialink, 'file' | 'externalMedia' | 'edumaticExercise' | 'miniDialog' | 'miniSite' | 'wordList' | 'kind'>,
  'eduhintExercise' | 'amountOfItems'
> & { kind: 'exercise-eduhint' };

function isEduhint(medialink: Medialink): medialink is EduhintMedialink {
  return medialink.kind === 'exercise-eduhint';
}

export type EdumaticMedialink = RequiredFields<
  Omit<Medialink, 'file' | 'externalMedia' | 'eduhintExercise' | 'miniDialog' | 'miniSite' | 'wordList' | 'kind'>,
  'edumaticExercise' | 'amountOfItems'
> & { kind: 'exercise-edumatic' };

function isEdumatic(medialink: Medialink): medialink is EdumaticMedialink {
  return medialink.kind === 'exercise-edumatic';
}

export type AssessmentQMedialink = RequiredFields<
  Omit<
    Medialink,
    'file' | 'externalMedia' | 'miniDialog' | 'miniSite' | 'wordList' | 'kind' | 'eduhintExercise' | 'edumaticExercise'
  >,
  'assessmentQExercise' | 'amountOfItems'
> & { kind: 'exercise-assessmentq' };

function isAssessmentQ(medialink: Medialink): medialink is AssessmentQMedialink {
  return medialink.kind === 'exercise-assessmentq';
}

type BaseExternalMediaMedialink = RequiredFields<
  Omit<Medialink, 'wordList' | 'file' | 'edumaticExercise' | 'eduhintExercise' | 'miniSite' | 'miniDialog' | 'kind'>,
  'externalMedia'
> & { kind: 'external-media' };

export type ExternalMediaMedialinkWeblink = BaseExternalMediaMedialink & {
  externalMedia: MedialinkExternalMediaWeblink;
};

export type ExternalMediaMedialinkGeoGebra = BaseExternalMediaMedialink & {
  externalMedia: MedialinkExternalMediaGeoGebra;
};

export type ExternalMediaMedialinkH5P = BaseExternalMediaMedialink & {
  externalMedia: MedialinkExternalMediaH5P;
};

export type ExternalMediaMedialinkYoutube = BaseExternalMediaMedialink & {
  externalMedia: MedialinkExternalMediaYoutube;
};

export type ExternalMediaMedialink =
  | ExternalMediaMedialinkWeblink
  | ExternalMediaMedialinkGeoGebra
  | ExternalMediaMedialinkH5P
  | ExternalMediaMedialinkYoutube;

export const isExternalMedia = (medialink: Medialink): medialink is ExternalMediaMedialink => {
  return 'externalMedia' in medialink;
};

export const isExternalMediaWeblink = (
  medialink: ExternalMediaMedialink,
): medialink is ExternalMediaMedialinkWeblink => {
  return medialink.externalMedia.type === 'weblink';
};

export const isExternalMediaGeoGebra = (
  medialink: ExternalMediaMedialink,
): medialink is ExternalMediaMedialinkGeoGebra => {
  return medialink.externalMedia.type === 'geogebra';
};

export const isExternalMediaH5P = (medialink: ExternalMediaMedialink): medialink is ExternalMediaMedialinkH5P => {
  return medialink.externalMedia.type === 'h5p';
};

export const isExternalMediaYoutube = (
  medialink: ExternalMediaMedialink,
): medialink is ExternalMediaMedialinkYoutube => {
  return medialink.externalMedia.type === 'youtube';
};

type MedialinkStudentAssignment = Omit<Medialink, 'assignments'> & { assignments: StudentAssignment[] };

export const isMedialinkStudentAssignment = (medialink: Medialink): medialink is MedialinkStudentAssignment => {
  return medialink.assignments.some(assignment => 'current' in assignment);
};

export function isScorableMedialink(medialink: Medialink): medialink is EduhintMedialink | EdumaticMedialink {
  return isEduhint(medialink) || isEdumatic(medialink) || isAssessmentQ(medialink);
}

export function isMedialinkWithScore(medialink: Medialink): medialink is RequiredFields<Medialink, 'score'> {
  return 'score' in medialink;
}

export const getMedialinksForNode = async (
  moduleId: string,
  nodeId: string,
  includeScore: boolean,
  onlyExercises: boolean,
) => {
  const { data } = await api.get<ResponsePayloadWithData<Medialink[]>>(
    `/studio/modules/${moduleId}/table-of-content/${nodeId}/medialinks?includeScore=${includeScore}&onlyExercises=${onlyExercises}`,
  );

  return data;
};

export type Word = {
  id: string;
  moduleId: string;
  nodeId: string;
  hierarchy: NodeHierarchy[];
  untranslated: string;
  translated: string;
  image?: string;
  sentence?: string;
  extra?: string;
  synonyms?: string;
  filters?: {
    wordType?: string;
  };
  maleVoice?: string;
  femaleVoice?: string;
};

export type Wordlist = {
  id: string;
  name: string;
  moduleId: string;
  nodeId: string;
  canSwitchLanguage: boolean;
  type: 'single' | 'compiled';
  sourceLanguage: {
    name: string;
    abbreviation: string;
  };
  targetLanguage: {
    name: string;
    abbreviation: string;
  };
  words: Word[];
  createdAt: string;
  updatedAt: string;
};

export const getWordlist = async (moduleId: string, wordlistId: string) => {
  const { data } = await api.get<Wordlist>(`/studio/modules/${moduleId}/word-lists/${wordlistId}`);

  return data;
};

export const getFavoriteWordsForModule = async (moduleId: string) => {
  const { data } = await api.get<ResponsePayloadWithData<string[]>>(`/studio/user/modules/${moduleId}/words`);

  return data;
};

export const putFavoriteWord = async (moduleId: string, wordId: string) => {
  await api.put(`/studio/user/modules/${moduleId}/words/${wordId}`);
};

export const deleteFavoriteWord = async (moduleId: string, wordId: string) => {
  await api.delete(`/studio/user/modules/${moduleId}/words/${wordId}`);
};

export const deleteFavoriteWords = async (moduleId: string, words: string[]) => {
  await api.post(`/studio/user/modules/${moduleId}/words/delete`, { words });
};

export const getSignedUrls = async (signedUrlsToFetch: string[]) => {
  const { data } = await api.post<ResponsePayloadWithData<Record<string, string>>>(
    '/s3-files/sign-urls',
    { ids: signedUrlsToFetch },
    { params: { extendedTimeValidity: true } },
  );

  return data;
};

export const getSignedUrl = async (medialinkId: string) => {
  const { data } = await api.get<{ url: string }>(`/medialinks/${medialinkId}/signedurl?extendedTimeValidity=true`);

  return data;
};

export const getSignedUrlForPreview = async (fileId: string) => {
  const {
    headers: { location },
  } = await api.get<{ headers: { location: string } }>(`/s3-files/${fileId}/signedurl?preview=true`);

  return { url: location };
};

export const getFaqTutorial = async (faqTutorialId: string) => {
  const { data } = await api.get<FaqTutorialItem>(`/studio/tutorials/${faqTutorialId}`);

  return data;
};

export const getUrlForMiniSite = async (moduleId: string, medialinkId: string) => {
  const { data } = await api.get<{ url: string }>(`/studio/modules/${moduleId}/medialinks/${medialinkId}/mini-site`);

  return data;
};

export type MedialinkQueryParams = {
  limit?: number;
  ids?: string;
  exerciseCategoryId?: string;
  includeSubModules?: boolean;
  includeAllGroups?: boolean;
  includeScore?: boolean;
  contentTypeId?: string;
};

export async function getMedialinks(moduleId: string, queryParams?: MedialinkQueryParams) {
  const { data } = await api.get<{
    data: Medialink[];
    total: number;
  }>(`/studio/modules/${moduleId}/medialinks`, {
    params: queryParams,
  });

  return data;
}

export async function getMedialinkById(moduleId: string, mediaLinkId: string, queryParams?: MedialinkQueryParams) {
  const { data } = await getMedialinks(moduleId, {
    ...queryParams,
    limit: 1,
    ids: mediaLinkId,
  });

  return data[0];
}

export async function updateMedialinkReviewState(
  medialinkId: string,
  reviewState: MedialinkReviewState,
  moduleId: string,
) {
  await api.patch(`/studio/modules/${moduleId}/medialinks/${medialinkId}/review-state`, { reviewState });
}
