import { NavLink } from 'react-router-dom';
import useRouting from '../../../hooks/useRouting';
import { useTranslation } from 'react-i18next';

export default function BackToDashboard() {
  const { t } = useTranslation();
  const { r } = useRouting();

  return (
    <NavLink to={r('dashboard')} className="back-to-dashboard caption-2">
      <svg role="img" width={16} height={16}>
        <use xlinkHref="#icon-arrow-left" />
      </svg>
      <img src={process.env.PUBLIC_URL + '/images/p_logo.svg'} alt="Pelckmans logo" className="p-logo" />
      <span dangerouslySetInnerHTML={{ __html: t('MODULES.MATERIAL.SIDEBAR.TITLE') }} />
    </NavLink>
  );
}
