import { useTranslation } from 'react-i18next';
import { NodeWithoutDescription } from '../modules/material/_api/TableOfContentsApi';

export const getNodeDescription = (node: NodeWithoutDescription, t: ReturnType<typeof useTranslation>['t']) => {
  if (node.id === 'general') return t('TOC_GENERAL');

  return node.displayPrefix ? `${node.prefix} ${node.title}` : node.title;
};

export const getFullHierarchyDescription = (
  hierarchy: NodeWithoutDescription[],
  t: ReturnType<typeof useTranslation>['t'],
) => {
  const descriptions = hierarchy.map(x => getNodeDescription(x, t));

  return descriptions.join(': ');
};
