import { useContext } from 'react';
import useBaoRouter from './useBaoRouter';
import useDefaultRouter from './useDefaultRouter';
import useVORouter from './useVORouter';
import FeatureTogglesContext from '../contexts/FeatureTogglesContext';
import useIanuaRouter from './useIanuaRouter';

export default function useRoutes() {
  const { REACT_APP_P_STUDIO_GROUP } = process.env;

  const defaultRoutes = useDefaultRouter();
  const baoRoutes = useBaoRouter();
  const voRoutes = useVORouter();
  const ianuaRoutes = useIanuaRouter();

  const { IANUA } = useContext(FeatureTogglesContext);

  return (
    <>
      {['SO', 'ES'].includes(REACT_APP_P_STUDIO_GROUP) && defaultRoutes}
      {REACT_APP_P_STUDIO_GROUP === 'BAO' && baoRoutes}
      {REACT_APP_P_STUDIO_GROUP === 'VO' && voRoutes}
      {IANUA && ianuaRoutes}
    </>
  );
}
