import api, { ResponsePayloadWithData } from '../../../api';
import { Provider } from '../../login/types';
import { FormValues as TeacherProfileRequestPayload } from '../reducers/TeacherProfileFormReducer';

export type School = {
  id: string;
  name: string;
  address: {
    streetName: string;
    streetNumber: string;
    zipCode: string;
    city: string;
  };
};

export type ImageInfo = {
  predefinedImage: string;
  customImage?: string;
};

type BaseIdentity = {
  id: string;
  fullName: string;
  email?: string;
  url: string;
};

export type KnooppuntIdentity = BaseIdentity & {
  provider: 'knooppunt';
};

export type SmartschoolIdentity = BaseIdentity & {
  provider: 'smartschool';
};

export type AccountResponsePayload = {
  id: string;
  firstName: string;
  lastName: string;
  nickName?: string;
  primaryEmail?: string;
  verifiedEmail?: boolean;
  existingPassword?: boolean;
  role: string;
  isCandidateTeacher: boolean;
  background: ImageInfo;
  avatar: ImageInfo;
  identities?: (KnooppuntIdentity | SmartschoolIdentity)[];
  school: {
    id: string;
    name: string;
    address: {
      city: string;
    };
  };
  smartschoolSchools: SmartschoolSchool[];
  unsyncedSmartschoolUrls: string[];
  provider: Provider;
};

export type EducationalNetwork = 'GO' | 'KO' | 'OVSG' | 'RESEAU_OFFICIEL' | 'RESEAU_LIBRE';

export enum EducationalType {
  A_STROOM = 'a-stroom',
  B_STROOM = 'b-stroom',
  ASO = 'ASO',
  TSO = 'TSO',
  KSO = 'KSO',
  BSO = 'BSO',
  KLEUTERONDERWIJS = 'Kleuteronderwijs',
  LAGER_ONDERWIJS = 'Lager onderwijs',
  VOLWASSENENONDERWIJS = 'Volwassenenonderwijs',
}

export type Subject = {
  id: string;
  name: string;
  alternativeIds?: string[];
};

export type SubjectYear = {
  subject: Subject;
  years: (1 | 2 | 3 | 4 | 5 | 6 | 7)[];
};

export type TeacherSchool = {
  id: string;
  name: string;
  address: {
    city: string;
  };
};

export type OneRoster = {
  enabled: boolean;
  clientId: string;
  clientSecret: string;
};

export type SmartschoolSchool = {
  id: string;
  name: string;
  domain: string;
  userIsAdmin?: boolean;
  isSyncStale?: boolean;
  oneRoster?: OneRoster;
};

export type TeacherProfileResponse = {
  educationalNetworks: EducationalNetwork[];
  educationalTypes: EducationalType[];
  subjectYears: SubjectYear[];
  schools: TeacherSchool[];
  smartschoolSchools: SmartschoolSchool[];
};

export async function fetchTeacherProfile(): Promise<TeacherProfileResponse> {
  const { data } = await api.get<TeacherProfileResponse>('my-account/teacher');

  return data;
}

export async function fetchAccount(): Promise<AccountResponsePayload> {
  const { data } = await api.get<AccountResponsePayload>('/my-account');

  return data;
}

export type AccountRequestPayload = {
  nickName?: string;
  school?: {
    id: string;
  };
  background?: {
    predefinedImage?: string;
    customImage?: string;
  };
  avatar?: {
    predefinedImage?: string;
    customImage?: string;
  };
};

export async function updatePersonalInformation({
  nickName,
  school,
  background,
  avatar,
}: AccountRequestPayload): Promise<AccountResponsePayload> {
  const { data } = await api.patch<AccountResponsePayload>('/my-account', {
    nickName,
    ...(school ? { school: { id: school.id } } : undefined),
    background,
    avatar,
  });

  return data;
}

export async function getSchools(zipCode?: string, name?: string) {
  const {
    data: { data },
  } = await api.get<ResponsePayloadWithData<School[]>>(`/studio/schools?zipCode=${zipCode}&name=${name}`);

  return data;
}

export async function syncAccount() {
  const { data } = await api.post<{ access_token: string; refresh_token: string }>('/my-account/sync');

  return data;
}

export async function updateTeacherAccount(requestPayload: TeacherProfileRequestPayload) {
  await api.put<TeacherProfileResponse>('/my-account/teacher', requestPayload);
}

export async function fetchSanitizedSubjects(studioGroup: StudioGroup) {
  const {
    data: { data },
  } = await api.get<ResponsePayloadWithData<Subject[]>>(`/studio/subjects/sanitized?group=${studioGroup}`);

  return data;
}

export async function createUploadRequestForCustomBackground() {
  const { data } = await api.post<{ id: string; uploadUrl: string }>('/studio/user/backgrounds');

  return data;
}

export async function createUploadRequestForCustomAvatar() {
  const { data } = await api.post<{ id: string; uploadUrl: string }>('/studio/user/avatars');

  return data;
}
